import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import PreorderTicket from './PreorderTicket'
import LoadingIndicator from "./LoadingIndicator";
import L10n from "../services/Locale";
import { PreorderSelectionContext } from "../context/PreorderSelectionContext";
import { APIRequest } from "../services/API";
import { Content } from "./Content";
import Helper from "../services/Helper";
import Events from "../services/Events";
import Session from "../services/Session";
import Modal from "./Modal";
import MaterialIcon from "./MaterialIcon";
import Slider from "./Slider";
import EventInfo from "./EventInfo";
import Carousel from "./Carousel";
import HTMLReactParser from "html-react-parser";
import Icon from "./Icon";
import Settings from "../services/Settings";
import Calendar from "./Calendar";
import { StarMeter } from "./Encuesta";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import SubscriptionsService from "../services/Subscriptions";

export default function Upselling(props) {
    const ModalPage = {
        Main: "Main",
        Calendar: "Calendar",
        Extras: "Extras",
        EventInfoText: "EventInfoText"
    };

    const [ loading, setLoading ] = useState(false);
    const [ event, setEvent ] = useState();
    const [ booking, setBooking ] = useState();
	const [ total, setTotal ] = useState(0);
	const [ formattedTotal, setFormattedTotal ] = useState();
    const [ preorderSelection, setPreorderSelection ] = useState([]);
    const [ extraEvents, setExtraEvents ] = useState([]);
    const [ negocios, setNegocios ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ modalSelection, setModalSelection ] = useState(null);
    const modalRef = useRef();

    const categoriasScrollAnimIntervalRef = useRef();
    const [ modalPage, setModalPage ] = useState(ModalPage.Main);
    const [ modalWantsPage, setModalWantsPage ] = useState(ModalPage.Main);

    const subscriptionContext = useContext(SubscriptionsContext);

	const slug = useParams().slug;

    const onWheel = e => {
        if (!Helper.IsResponsive()) {
            const delta = e.originalEvent.deltaY * (e.originalEvent.webkitDirectionInvertedFromDevice ? -1 : 1);
            const rect = $(".sidebar .inner")[0].getBoundingClientRect();
            let offset = rect.top;

            if (rect.bottom > window.innerHeight - 10 && delta > 0) {
                offset -= delta;
            } else if (rect.top < $("header.desktop").outerHeight(true) + 20 && delta < 0) {
                offset -= delta;
            }

            offset = Math.min($("header.desktop").outerHeight(true) + 20, Math.max(window.innerHeight - 10 - rect.height, offset));

            $(".sidebar .inner").css({ top: offset });
        }
    };

    const onScroll = e => {
        let done = false;
        $(".main .extras .category").each(function(e) {
            if (!done && this.getBoundingClientRect().top > 80) {
                done = true;
                let m = this.id.match(/.+?-(\d+)/);
                if (m && m.length > 1) {
                    $(".main > .categorias a").removeClass("active");
                    $(".main > .categorias a[href='#" + m[1] + "']").addClass("active");
                }
            }
        });

        if ($(".categorias").length) {
            clearInterval(categoriasScrollAnimIntervalRef.current);
            $(".categorias")[0].scrollTarget = $(".categorias .inner a.active").offset()?.left;
            $(".categorias")[0].currentScrollOffset = $(".categorias").scrollLeft();

            categoriasScrollAnimIntervalRef.current = setInterval(() => {
                if (!$(".categorias").length) {
                    clearInterval(categoriasScrollAnimIntervalRef.current);
                    return;
                }

                $(".categorias")[0].currentScrollOffset += ($(".categorias")[0].scrollTarget - $(".categorias")[0].currentScrollOffset) / 10;
                $(".categorias").scrollLeft($(".categorias")[0].currentScrollOffset);

                if ($(".categorias")[0].scrollTarget == $(".categorias")[0].currentScrollOffset) {
                    clearInterval(categoriasScrollAnimIntervalRef.current);
                }
            }, 5);
        }
    };

    const onResize = () => {
        if (Helper.IsResponsive()) {
            $(".venue-extras .sidebar:not(.active) .inner").css({
                top: window.innerHeight - 130
            });
            $(".venue-extras .sidebar.active .inner").css("top", "");
        }
    };

    useEffect(() => {
        if (!event) {
            setTotal(0);
            return;
        }

        let total = (event.availability.price != -1 ? event.availability.price : event.price) * Session.GetBookingData("pax");
        preorderSelection.forEach(it => {
            total += it.quantity * it.price;
        });
        extraEvents.forEach(it => {
            total += it.quantity * it.price;
            it.selectedExtras.forEach(e => {
                total += e.quantity * e.price;
            });
        });
        setTotal(total);
    }, [ event, extraEvents, preorderSelection ]);

    useEffect(() => {
        setFormattedTotal(Helper.FormatAmount(total / 100));
    }, [ total ]);

    useEffect(() => {
        if (modalWantsPage == ModalPage.Extras) {
            if (modalSelection.extras.length == 0) {
                confirmModal();
                return;
            }
        }
        if (modalWantsPage != modalPage) setModalPage(modalWantsPage);
    }, [ modalWantsPage ]);

    useEffect(() => {
        $(".categorias .inner").css({
            width: "",
            display: "",
            justifyContent: "",
            alignItems: ""
        });

        $(".categorias .inner a").css({
            minWidth: "",
            flex: ""
        });

        let totalWidth = 0;
        $(".categorias .inner a").each(function() {
            totalWidth += $(this).outerWidth(true);
        });

        if (totalWidth > $(".categorias").outerWidth(true)) {
            $(".categorias").css({
                overflow: "auto",
                background: "white"
            });

            $(".categorias .inner a").each(function() {
                $(this).css({
                    minWidth: $(this).outerWidth(true)
                });
            });

            $(".categorias .inner").css({
                width: totalWidth,
                display: "flex",
                alignItems: "center"
            });
        } else {
            $(".categorias .inner").css({
                width: $(".categorias").outerWidth(true),
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center"
            });

            $(".categorias .inner a").each(function() {
                $(this).css({
                    minWidth: $(this).outerWidth(true),
                    flex: 1
                });
            });
        }

        onScroll();
        onResize();

        $(window).on("resize", onResize);

        if($(".sidebar .inner")[0] && $(".sidebar .inner")[0].currentOffset == undefined) $(".sidebar .inner")[0].currentOffset = 107;

        const onWheel = e => {
            if (!Helper.IsResponsive()) {
                let delta = e.originalEvent.deltaY;
                if (event.webkitDirectionInvertedFromDevice) delta = -delta;
                const rect = $(".sidebar .inner")[0].getBoundingClientRect();
                let currentOffset = parseInt($(".sidebar .inner")[0].currentOffset);

                if (rect.top < 107 || rect.bottom >= window.innerHeight - 25) {
                    if (delta > 0 && rect.bottom >= window.innerHeight - 25) {
                        currentOffset = Math.max(window.innerHeight - 25 - rect.height, currentOffset - delta);
                    } else if (delta < 0 && rect.top < 107) {
                        currentOffset = Math.min(107, currentOffset - delta);
                    }
                }

                $(".sidebar .inner").css({
                    position: "fixed",
                    top: currentOffset
                });

                $(".sidebar .inner")[0].currentOffset = currentOffset;
            }
        };

        $(window).on("wheel", onWheel);
        $(window).on("resize", onResize);

        const headerHeight = $("header.desktop").outerHeight(true);

        $("body").append(`
            <div class="injected-css-extras">
                <style>
                    body.scrolled .venue-extras .main > .categorias .inner {
                        top: ${headerHeight}px;
                    }

                    body .sidebar .inner {
                        top: ${headerHeight + 20}px;
                    }

                    body.scrolled .sidebar .inner {
                        top: ${headerHeight + 20}px;
                    }
                </style>
            </div>
        `);

        return () => {
            $("#spotnow").css({ minHeight: "" });
            $(window).off("wheel", onWheel);
            $(window).off("resize", onResize);
			$(".injected-css-extras").remove();
        };
    }); // After render.

    useEffect(() => {
        setLoading(true);

        Promise.all([
            Events.GetEventSlot(Session.GetBookingData("selected_availability_id")),
            Events.GetAllWithAvailability(),
        ]).then(([event, negocios]) => {
            setBooking(Session.GetBookingData());
            setEvent(event);
            setNegocios(negocios);
            const categories = negocios.filter(n => n.events.filter(e => e.availability.filter(a => a.pax > 0).length > 0).length > 0).map(n => { return { id: n.tipo.id, title: n.tipo.plural };});
            setCategories(categories.filter((c, i) => categories.findIndex(c2 => c2.id == c.id) === i));

            setPreorderSelection(Session.GetBookingData("preorder_selection") || []);
            setExtraEvents(Session.GetBookingData("extra_events") || []);

            setLoading(false);
        });

        Settings.Get("LOGO_HEIGHT", 65).then(height => {
            $("body").append('<div class="injected-css"><style>body.scrolled .venue-extras .main > .categorias .inner { top: ' + (parseInt(height) + 5) + 'px; }</style></div>');
        });

        SubscriptionsService.IsEnabled().then(enabled => {
			if (enabled) {
                history.replaceState({}, "", "/pago");
                location.reload();
            }
		});

        $("header").addClass("simple");
        $(window).on("scroll", onScroll);
        $(window).on("wheel", onWheel);

        return () => {
			$("header").removeClass("simple");
            $(window).off("scroll", onScroll);
            $(window).off("wheel", onWheel);
		};
    }, []);

    const changeSelection = useCallback(newSelection => {
        setExtraEvents(newSelection);
    }, [ setExtraEvents ]);

    if (loading) return <LoadingIndicator />;

    const hasTotal = preorderSelection.filter(e => e.quantity > 0).length > 0;

    const confirmModal = () => {
        const n = [...extraEvents];
        const index = n.findIndex(e => e.id == modalSelection.id);
        if (index != -1) {
            n[index] = {...modalSelection};
        } else {
            n.push({...modalSelection});
        }
        setExtraEvents(n);
        Session.SetBookingData("extra_events", extraEvents);
        modalRef.current.close();
    }

    const renderEventInfoTextPage = (modal, negocio) => {
        const event = modal.state.event_info_text_event;
        return <div className="modal-page event-info-text-page">
            <div
                className="event-image"
                style={{
                    backgroundImage: "url(" + (event.image_url[0] == "/" ? event.image_url : "/static/images/eventos/" + event.image_url) + ")"
                }}>
                &nbsp;
            </div>
            <p/>
            <div style={{ padding: 15 }} dangerouslySetInnerHTML={{ __html: event.description }} />
        </div>;
    }

    const renderMainModalPage = (modal, negocio) => {
        return <div className="modal-page main-page">
            {negocio.slider && (
                <Slider
                    style={{
                        opacity: negocio.slider.length ? 1 : 0,
                        height: 350,
                        minHeight: 350
                    }}
                    height={350}
                    effect="fade"
                    delay={5000}
                    slides={negocio.slider}
                />
            )}
            {!negocio.slider && <div
                className="image"
                style={{
                    backgroundImage: "url(" + (negocio.header_image_url[0] == "/" ? negocio.header_image_url : "/static/images/eventos/" + negocio.header_image_url) + ")"
                }}
            />}

            <div className="inner">
                <div className="title">{negocio.name}</div>
                <div className="address">{negocio.address}<br/>{negocio.city}</div>

                <EventInfo negocio={negocio} />

                <div className="events">
                    <Carousel
                        className="items"
                        elements={negocio.events.filter((event) => event.availability.reduce((pax, row) => pax + row.pax, 0) > 0)}
                        items={2}
                        renderElement={(event, idx) => {
                            const addedEvent = extraEvents.find((av) => av.id == event.id);
                            const image_url = event.image_url || negocio.image_url;

                            let minimumPrice = -1;
                            let minDuration = 0;
                            event.availability.forEach((availability) => {
                                if (availability.duration > 0 && (minDuration == 0 || availability.duration > minDuration)) {
                                    minDuration = availability.duration;
                                }

                                if (availability.price != -1) {
                                    if (minimumPrice == -1 || availability.price < minimumPrice) {
                                        minimumPrice = availability.price;
                                    }
                                } else {
                                    if (event.price != -1) {
                                        if (minimumPrice == -1 || event.price < minimumPrice) {
                                            minimumPrice = event.price;
                                        }
                                    } else {
                                        if (negocio.price != -1 && (negocio.price < minimumPrice || minimumPrice == -1)) {
                                            minimumPrice = negocio.price;
                                        }
                                    }
                                }
                            });

                            return (
                                <div
                                    className="item"
                                    style={{
                                        width: Helper.IsResponsive() ? "calc(100vw - 91px)" : "auto"
                                    }}
                                    key={idx}
                                    >
                                    <div className="image-container cover-zoom-listener"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalSelection({
                                            ...event,
                                            negocio,
                                            selectionLength: modalSelection.selectionLength || 1,
                                            bookedDate: modalSelection.bookedDate || null,
                                            slots: modalSelection.slots || [],
                                            selectedSlot: modalSelection.selectedSlot || "",
                                            selectedDates: modalSelection.selectedDates || [],
                                            quantity: modalSelection.quantity || 1,
                                            extras: modalSelection.extras || []
                                        });
                                        modal.setState({
                                            onBack: () => {
                                                modal.setState({
                                                    onBack: null,
                                                    custom_title: ""
                                                });
                                                setModalWantsPage(ModalPage.Main);
                                            },
                                            custom_title: event.nombre
                                        });
                                        setModalWantsPage(ModalPage.Calendar);
                                    }}
                                    >
                                        <div
                                            className="item-image cover-zoom"
                                            style={{
                                                backgroundImage: "url(" + (image_url[0] == "/" ? image_url : "/static/images/eventos/" + image_url) + ")"
                                            }}>
                                            <img alt="" src={image_url[0] == "/" ? image_url : "/static/images/eventos/" + image_url} />
                                        </div>
                                        <h3>{event.nombre}</h3>
                                        <div className="bottom-details">
                                            <div className="item-price details-tag">{L10n.__("Desde")} {Helper.FormatAmount(minimumPrice / 100.0)}</div>
                                            {minDuration > 0 && <div className="item-time details-tag">{Helper.FormatDuration(minDuration, true)}</div>}
                                        </div>
                                    </div>
                                    <div className="description-text">
                                        <h3 className="event-info-name">{event.nombre}</h3>
                                        {Helper.Ellipsis(event.description, 110, () => <a href="#" className="read-more-link" onClick={e => {
                                                e.preventDefault();
                                                modal.setState({
                                                    onBack: () => {
                                                        modal.setState({
                                                            onBack: null,
                                                            custom_title: ""
                                                        });
                                                        setModalWantsPage(ModalPage.Main);
                                                    },
                                                    custom_title: event.nombre,
                                                    event_info_text_event: event
                                                });
                                                setModalWantsPage(ModalPage.EventInfoText);
                                            }}>{L10n.__("+ Info")}</a>
                                        )}
                                    </div>
                                    {addedEvent?.quantity && <div className="quantity">{Helper.FormatAmount((addedEvent.quantity * parseInt(addedEvent.price) + (addedEvent.extras?.reduce((acc, extra) => acc + parseInt(extra.price) * extra.qty, 0) || 0)) / 100)}</div>}
                                </div>
                            );
                        }}
                    />
                </div>

                <div className="description" dangerouslySetInnerHTML={{ __html: negocio.description }} />

                {negocio.iconos_detalle && (
                    <div className="facts">
                        {JSON.parse(negocio.iconos_detalle)?.map((detalle, idx) => {
                            return (
                                <div key={idx}>
                                    <img className="icon-image" src={detalle.image_url} />
                                    <div>
                                        <div>
                                            <b>{detalle.titulo}</b>
                                        </div>
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: detalle.list }} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {negocio.average_rating && negocio.average_rating.map && <div className="encuestas-clientes">
                    <div className="columna" style={{ width: window.innerWidth > 992 ? null : negocio.average_rating.length * window.innerWidth * 0.63 - window.innerWidth + 300 }}>
                        {negocio.average_rating.map((item, index) => {
                            return <div className="category" key={"survey-data-category" + index}>
                                <h2>{item.text}</h2>
                                <StarMeter defaultValue={item.value} />
                            </div>;
                        })}
                    </div>
                </div>}
            </div>
        </div>;
    }

    const renderCalendarModalPage = (modal, negocio) => {
        return <div className="modal-page calendar-page">
            <div className="top-bar">
                <div className="pax-selector">
                    <div className="pax">{modalSelection.quantity + " " + (modalSelection.quantity == 1 ? L10n.__("persona") : L10n.__("personas"))}</div>
                    <div
                        className="pax-button"
                        onClick={(e) => {
                            e.preventDefault();
                            const newSelection = { ...modalSelection };
                            newSelection.quantity = Math.max(1, modalSelection.quantity - 1);
                            setModalSelection(newSelection);
                        }}>
                        <Icon name="minus" size={1.5} style={{ marginRight: 0 }}/>
                    </div>
                    <div
                        className="pax-button"
                        onClick={(e) => {
                            e.preventDefault();
                            const newSelection = { ...modalSelection };
                            newSelection.quantity = Math.max(1, modalSelection.quantity + 1);
                            setModalSelection(newSelection);
                        }}>
                        <Icon name="plus" size={1.5} style={{ marginRight: 0 }}/>
                    </div>
                </div>
                {modalSelection.negocio.tipo == "alojamiento" && (
                    <div className="selection-length-selector">
                        <div className="selection-length">{modalSelection.selectionLength + " noche" + (modalSelection.selectionLength == 1 ? "" : "s")}</div>
                        <div
                            className="selection-length-button"
                            style={{
                                opacity: modalSelection.bookedDate ? 1.0 : 0.5
                            }}
                            onClick={(e) => {
                                if (!modalSelection.bookedDate) {
                                    return;
                                }

                                e.preventDefault();
                                const newSelection = { ...modalSelection };
                                newSelection.selectionLength = Math.max(1, modalSelection.selectionLength - 1);
                                setModalSelection(newSelection);
                            }}>
                            <Icon name="minus" size={1.5} />
                        </div>
                        <div
                            className="selection-length-button"
                            style={{
                                opacity: modalSelection.bookedDate ? 1.0 : 0.5
                            }}
                            onClick={(e) => {
                                if (!modalSelection.bookedDate) {
                                    return;
                                }

                                e.preventDefault();
                                const newSelection = { ...modalSelection };
                                let newSelectionLength = modalSelection.selectionLength;
                                for (let i = 0; i < modalSelection.selectionLength + 1; i++) {
                                    const isoDate = Helper.GetISODate(new Date(modalSelection.date.getFullYear(), modalSelection.date.getMonth(), modalSelection.date.getDate() + i));
                                    if (!modalSelection.calendar[isoDate]) break;
                                    newSelectionLength = i + 1;
                                }
                                newSelection.selectionLength = newSelectionLength;

                                setModalSelection(newSelection);
                            }}>
                            <Icon name="plus" size={1.5} />
                        </div>
                    </div>
                )}
                <div className="date-display">{modalSelection.bookedDate ? Helper.FormatDate(modalSelection.bookedDate, Helper.DATE_VERBOSE_SHORT) : L10n.__("Selecciona una fecha")}</div>
            </div>
            <Calendar
                className="calendario-evento"
                selectionLength={modalSelection.selectionLength}
                availabilityCalendar={modalSelection.calendar}
                defaultValue={modalSelection.bookedDate}
                onSelectionChanged={(dates) => {
                    if (dates && dates.length) {
                        const newData = { ...modalSelection };
                        newData.slots = modalSelection.calendar[Helper.GetISODate(dates[0])] || {};
                        newData.selectionLength = 1;
                        newData.selectedDates = dates;
                        newData.bookedDate = dates[0];
                        setModalSelection(newData);

                        setTimeout(() => {
                            $(".modal-content-area").animate({
                                scrollTop: $(".modal-content-area .slots")?.offset().top
                            }, 500);
                        }, 100);
                    }
                }}
            />
            {modalSelection.bookedDate && modalSelection.slots && (
                <div className="slots">
                    {Object.keys(modalSelection.slots).map((slot, idx) => {
                        let availablePax = modalSelection.slots[slot];
                        const availabilityRow = modalSelection.availability.find((row) => row.slot.substring(0, 16) == Helper.GetISODate(modalSelection.bookedDate) + " " + slot);

                        if (!availabilityRow) return null;

                        if (availabilityRow.id == booking.selected_availability_id) {
                            availablePax -= booking.pax;
                        }
                        const disabled = modalSelection.quantity > availablePax;

                        let slotPrice = 0;

                        if (modalSelection.negocio.disponibilidad_multiple || modalSelection.disponibilidad_multiple) {
                            modalSelection.selectedDates.forEach((date) => {
                                const otherAvailabilityRow = modalSelection.availability?.find((row) => row.slot.substring(0, 16) == Helper.GetISODate(date) + " " + slot);
                                slotPrice += parseFloat(otherAvailabilityRow?.price != -1 ? otherAvailabilityRow?.price : (modalSelection?.event?.price != -1 ? modalSelection?.event?.price : modalSelection?.negocio?.price));
                            });
                        } else {
                            slotPrice = parseFloat(availabilityRow.price != -1 ? availabilityRow.price : (modalSelection.price != -1 ? modalSelection.price : modalSelection.negocio.price));
                        }

                        availabilityRow.price = slotPrice;

                        return (
                            <div
                                className={"slot-row" + (disabled ? " disabled" : "") + ((modalSelection.selecteSlot?.split(" ") || ["", ""])[1].substring(0, 5) == slot ? " selected" : "")}
                                key={idx}
                                onClick={(e) => {
                                    if (disabled) return;

                                    modal.setState({
                                        onBack: () => {
                                            modal.setState({
                                                onBack: () => {
                                                    modal.setState({
                                                        onBack: null,
                                                        custom_title: ""
                                                    });
                                                    setModalWantsPage(ModalPage.Main);
                                                }
                                            });
                                            setModalWantsPage(ModalPage.Calendar);
                                        }
                                    });

                                    Promise.all([
                                        APIRequest("events/get-extras", { event_id: modalSelection.id }),
                                        APIRequest("events/get-extras-categories", { event_id: modalSelection.id })
                                    ]).then(([extras, extrasCategories]) => {
                                        setModalSelection({
                                            ...modalSelection,
                                            price: availabilityRow.price,
                                            selectedSlot: availabilityRow.slot,
                                            availabilityId: availabilityRow.id,
                                            pax: modalSelection.quantity,
                                            extras: extras.data,
                                            selectedExtras: modalSelection.selectedExtras || [],
                                            extrasCategories: extrasCategories.data
                                        });
                                        modal.setState({
                                            onBack: () => {
                                                modal.setState({
                                                    onBack: () => {
                                                        modal.setState({ onBack: null });
                                                        setModalWantsPage(ModalPage.Main);
                                                    }
                                                });
                                                setModalWantsPage(ModalPage.Calendar);
                                            },
                                            extrasTotal: 0
                                        })
                                        setModalWantsPage(ModalPage.Extras);
                                    });
                                }}>
                                <div className="slot-time">{Helper.FormatTimeWithDuration(slot, availabilityRow.duration || 0)}</div>
                                <div className="available-pax">{availablePax} {L10n.__("plazas")}</div>
                                {modalSelection.full_booking == 1 && <div className="slot-amount">{Helper.FormatAmount(slotPrice / 100.0)}</div>}
                                {modalSelection.full_booking != 1 && (
                                    <div className="slot-amount">
                                        {modalSelection.quantity} &times; {Helper.FormatAmount(slotPrice / 100.0)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>;
    }

    const renderExtrasModalPage = (modal, negocio) => {
        function recalculateTotal(newSelection) {
            let total = 0;
            newSelection.selectedExtras.forEach(ex => {
                total += ex.quantity * ex.price;
            });

            modal.setState({ extrasTotal: total });
        }

        return <div className="modal-page extras-page">
            {modalSelection.extrasCategories.map((cat, catIdx) => {
                return <div className="extra-category" key={"extracat"+catIdx+"-"+cat.id}>
                    <div className="title">{cat.title}</div>
                    <div className="description">{cat.description}</div>
                    <div className="category-items">
                        {modalSelection.extras.filter(e => e.category_id == cat.id).map((item, itemIdx) => {
                            const selectedItem = modalSelection.selectedExtras.find(ex => ex.id == item.id) || {...item};
                            if (typeof selectedItem.quantity === "undefined") selectedItem.quantity = 0;

                            return <div className="item" key={"extraitem" + itemIdx + "-" + item.id}>
                                <div className="image" style={{ backgroundImage: "url(" + (item.image_url[0] == "/" ? item.image_url : "/static/images/eventos/" + item.image_url) + ")" }} />
                                <div className="name">{item.name}</div>
                                <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
                                <div className="item-quantity-selector">
                                    <div className="item-price">
                                        {selectedItem.quantity > 0 && <div className="quantity">{selectedItem.quantity} &times;&nbsp;</div>}
                                        {Helper.FormatAmount(item.price / 100)}
                                    </div>
                                    <Icon
                                        name="minus"
                                        onClick={() => {
                                            const newSelection = {...modalSelection};
                                            let index = newSelection.selectedExtras.findIndex(e => e.id == item.id);
                                            if (index == -1) {
                                                newSelection.selectedExtras.push({...item});
                                                index = newSelection.selectedExtras.length - 1;
                                                newSelection.selectedExtras[index].quantity = 0;
                                            }
                                            newSelection.selectedExtras[index].quantity = Math.max(0, newSelection.selectedExtras[index].quantity - 1);
                                            recalculateTotal(newSelection);
                                            setModalSelection(newSelection);
                                        }}
                                    />
                                    <Icon
                                        name="plus"
                                        onClick={() => {
                                            const newSelection = {...modalSelection};
                                            let index = newSelection.selectedExtras.findIndex(e => e.id == item.id);
                                            if (index == -1) {
                                                newSelection.selectedExtras.push({...item});
                                                index = newSelection.selectedExtras.length - 1;
                                                newSelection.selectedExtras[index].quantity = 0;
                                            }
                                            if (item.stock == -1) {
                                                newSelection.selectedExtras[index].quantity++;
                                            } else {
                                                newSelection.selectedExtras[index].quantity = Math.min(item.stock, newSelection.selectedExtras[index].quantity + 1);
                                            }
                                            recalculateTotal(newSelection);
                                            setModalSelection(newSelection);
                                        }}
                                    />
                                </div>
                            </div>;
                        })}
                    </div>
                </div>;
            })}
            <div className="boton-confirmar-container">
                <a href="#" className="btn btn-brown" onClick={e => {
                    e.preventDefault();
                    confirmModal();
                }}>{L10n.__("Confirmar")}{modal.state.extrasTotal > 0 && <span>{Helper.FormatAmount(modal.state.extrasTotal / 100.0)}</span>}</a>
            </div>
        </div>;
    }

    if (!event) return null;

    return <div className="venue-extras upselling">
        <PreorderSelectionContext.Provider
			value={{
				preorderSelection: extraEvents,
				changeSelection,
				formattedTotal,
				total
		    }}
        >
            <div className="sidebar">
                <div className="inner">
                    <a href="#" className="ver-detalle" onClick={e => {
                        e.preventDefault();
                        $(".sidebar").toggleClass("active");
                        if ($(".sidebar").hasClass("active")) {
                            $("body").addClass("scroll-lock");
                        } else {
                            $("body").removeClass("scroll-lock");
                        }
                        onResize();
                    }}>{L10n.__("Ver detalle de la reserva")} <img src="/static/icons/desplegable-preguntas.png" /></a>
                    <div className="box">
                        <div className="image" style={{ backgroundImage: "url(" + (event.negocio.image_url[0] == "/" ? event.negocio.image_url : "/static/images/eventos/" + event.negocio.image_url) + ")" }} />
                        <div className="negocio-name">{event.negocio.name}</div>
                        {event.negocio.address && <div className="negocio-address">{event.negocio.address}</div>}
                        <PreorderTicket page="upselling" event={event} booking={booking} />
                    </div>
                    <div className="boton-confirmar-container"><a href="#" className={"btn btn-brown boton-confirmar space-between"} onClick={e => {
                        e.preventDefault();
                        Session.SetBookingData("extra_events", extraEvents);
                        Session.RedirectAuthenticated("/pago");
                    }}>{L10n.__("Confirmar total")} <span>{formattedTotal}</span>
                    </a></div>
                </div>
            </div>
            <div className="main">
                <div className="categorias">
                    <div className="inner">
                        {categories.map((cat, catIdx) => {
                            return <a href={"#" + cat.id} key={"catbtn" + catIdx + Helper.SngNormalize(cat.title)} onClick={e => {
                                e.preventDefault();
                                $("html, body").animate({
                                    scrollTop: $("#category-" + cat.id).offset().top - $(".main").offset().top - 40
                                }, 500);
                                $(".categorias a").removeClass("active");
                                $(".categorias a[href='#" + cat.id + "']").addClass("active");
                            }}>{cat.title}</a>;
                        })}
                    </div>
                </div>
                <div className="intro-extras"><Content slug="intro-upselling" /></div>
                <div className="extras">
                    {categories.map((cat, catIdx) => {
                        return <div className="category" id={"category-" + cat.id} key={"cat" + catIdx + Helper.SngNormalize(cat.title)}>
                            <h2>{cat.title}</h2>
                            <div className="category-extras">
                                {negocios.filter(n => n.tipo.id == cat.id && n.events.filter((event) => event.availability.reduce((pax, row) => pax + row.pax, 0) > 0).length > 0).map((extra, extraIdx) => {
                                    let averageRating = 0;
                                    let count = 0;
                                    extra.average_rating?.forEach(rating => {
                                        if (rating.value > 0) {
                                            averageRating += rating.value;
                                            count++;
                                        }
                                    });

                                    if (count > 0) {
                                        averageRating /= count;
                                    }

                                    const iconosDetalle = JSON.parse(extra.iconos_detalle || "[]");

                                    const selection = extraEvents.find(i => i.negocio.id == extra.id);
                                    return <div
                                    onClick={e => {
                                        e.preventDefault();
                                        extra.initialQuantity = extraEvents.find(i => i.id == extra.id)?.quantity || 0;
                                        setModalSelection(modalSelection || {...event});
                                        setModalPage(ModalPage.Main);
                                        modalRef.current.setData(extra);
                                        modalRef.current.open();
                                        modalRef.current.setTitle(extra.name);
                                    }}
                                    className="extra cover-zoom-listener" key={"extra" + extraIdx + Helper.SngNormalize(extra.name)}>
                                        {selection?.quantity > 0 && <div className="quantity">{selection.quantity}</div>}
                                        <div className="image cover-zoom" style={{ backgroundImage: "url(" + (extra.image_url[0] == "/" ? extra.image_url : "/static/images/eventos/" + extra.image_url) + ")" }}>
                                            {Math.floor(averageRating * 10) > 0 && <div className="mobile-only average-rating">
                                                <MaterialIcon name="star_rate" style={{ fontVariationSettings: '"FILL" 1' }} /> {(Math.floor(averageRating * 10) / 10).toLocaleString(L10n.GetLocale())}
                                            </div>}
                                            <div className="iconos">
                                                {extra.iconos.map((icono, iconoIndex) => {
                                                    return <div className="icon" key={"icono"+iconoIndex+"-"+extraIdx}>
                                                        <img src={icono.image_url} />
                                                        {icono.description != "" && <div className="icon-tooltip">{icono.description}</div>}
                                                    </div>;
                                                })}
                                            </div>
                                        </div>
                                        <h3>{extra.name}</h3>
                                        <div className="city">{extra.city}</div>
                                        <div className="lower">
                                            <div className="iconos-detalle">{iconosDetalle.map((icono, iconoIndex) => {
                                                return <div className="icon" key={"icono-detalle"+iconoIndex+"-"+extraIdx}>
                                                    <img src={icono.image_url} />
                                                    {icono.titulo != "" && <div className="icon-tooltip">{icono.titulo}</div>}
                                                </div>;
                                            })}</div>
                                            <div className="lower-tags">
                                                {extra.average_price != -1 && <div className="price-tag">Precio medio {Helper.FormatAmount(extra.average_price/100.0)}</div>}
                                                {Math.floor(averageRating * 10) > 0 && <div className="desktop-only average-rating">
                                                <MaterialIcon name="star_rate" style={{ fontVariationSettings: '"FILL" 1' }} /> {(Math.floor(averageRating * 10) / 10).toLocaleString(L10n.GetLocale())}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <Modal
                ref={ref => modalRef.current = ref}
                renderContent={(modal, extra) => {
                    return <div className="extras-modal">
                        {modalPage == ModalPage.Main && renderMainModalPage(modal, extra)}
                        {modalPage == ModalPage.Calendar && renderCalendarModalPage(modal, extra)}
                        {modalPage == ModalPage.Extras && renderExtrasModalPage(modal, extra)}
                        {modalPage == ModalPage.EventInfoText && renderEventInfoTextPage(modal, extra)}
                    </div>;
                }}
            />
        </PreorderSelectionContext.Provider>
    </div>;
}
