import { useCallback, useContext, useEffect, useState } from "react";
import { APIAdminRequest } from "../services/API.js";
import AdminService from "../services/Admin.js";
import AdminBar from "./AdminBar";
import LoadingIndicator from "./LoadingIndicator";
import Table from "./Table.jsx";

import Helper from "../services/Helper.jsx";
import L10n from "../services/Locale.jsx";
import Settings from "../services/Settings.js";

export default function AdminSuscripciones(props) {
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [month, setMonth] = useState(new Date().getMonth());
	const [year, setYear] = useState(new Date().getFullYear());
	const [brandName, setBrandName] = useState("");

	const setCommission = (id, percentage, isPack) => {
		APIAdminRequest("change-subscription-commissions", { id, commission_ratio: percentage, is_pack: isPack ? 1 : 0 }).then(() => {
			refreshData();
		});
	};

	const setStripeSharedRatio = (id, percentage, isPack) => {
		APIAdminRequest("change-subscription-commissions", { id, stripe_commission_ratio: percentage, is_pack: isPack ? 1 : 0 }).then(() => {
			refreshData();
		});
	};

	const refreshData = () => {
		setLoading(true);
		APIAdminRequest("get-subscriptions-accounting-totals", { start_date: Helper.GetISODate(new Date(year, month, 1)) }).then((response) => {
			setRows(response.data.rows);
			console.log(response);
			setLoading(false);
		});
	};

	const loadMonthData = () => {
		refreshData();
	};

	useEffect(() => {
		loadMonthData();
	}, [month, year]);

	useEffect(() => {
		setMonth(new Date().getMonth());
		setYear(new Date().getFullYear());

		Settings.Get("BRAND_NAME").then((value) => {
			setBrandName(value);
		});

		document.body.classList.add("bg-grey", "has-header");

		return () => {
			document.body.classList.remove("bg-grey", "has-header");
		};
	}, []);

	return (
		<div className="admin-page admin-suscripciones">
			{loading && <LoadingIndicator />}
			<AdminBar />
			<div className="header">
				<h2 style={{ marginRight: "auto" }}>{L10n.__("Suscripciones a negocios")}</h2>
				<div className="date-selector">
					<select defaultValue={month} onChange={(e) => setMonth(e.target.value)}>
						<option value="0">{L10n.__("Enero")}</option>
						<option value="1">{L10n.__("Febrero")}</option>
						<option value="2">{L10n.__("Marzo")}</option>
						<option value="3">{L10n.__("Abril")}</option>
						<option value="4">{L10n.__("Mayo")}</option>
						<option value="5">{L10n.__("Junio")}</option>
						<option value="6">{L10n.__("Julio")}</option>
						<option value="7">{L10n.__("Agosto")}</option>
						<option value="8">{L10n.__("Septiembre")}</option>
						<option value="9">{L10n.__("Octubre")}</option>
						<option value="10">{L10n.__("Noviembre")}</option>
						<option value="11">{L10n.__("Diciembre")}</option>
					</select>
					<select defaultValue={year} onChange={(e) => setYear(e.target.value)}>
						<option value="2024">2024</option>
					</select>
				</div>
			</div>
			{rows?.length > 0 && (
				<Table
					showDetailsArrowVisible={(row) => row.extras?.length > 0}
					widths={[3, 2, 3, 1, 1, 2, 2, 2, 2]}
					header={["Cliente", "Usuario", "Evento / Pack", "Personas", "Visitas", "Suscripción", "Fecha", brandName, "Negocio"]}
					totalizedRow={{
						testValue: (row) => row.pack + " - " + row.negocio_id,
						renderColumn: (columnIndex, rows) => {
							switch (columnIndex) {
								default:
									return null;
								case 1:
									return (
										<>
											<label>
												{L10n.__("Comisión") + " " + brandName}{" "}
												<input
													maxLength={4}
													min={0}
													max={100}
													className="percent-input"
													defaultValue={rows[0].commission_ratio + " %"}
													onFocus={(e) => {
														const m = e.target.value.trim().match(/([\d,.]+)/);
														if (m.length > 1) e.target.value = m[1];
														else e.target.value = "0";
														e.target.select();
													}}
													onKeyDown={(e) => {
														if (e.key == "Enter") {
															e.preventDefault();
															const m = e.target.value.trim().match(/([\d,.]+)/);
															if (m.length > 1) e.target.value = m[1];
															else e.target.value = "0";
															setCommission(rows[0].pack == 1 ? rows[0].pack_id : rows[0].negocio_id, parseFloat(e.target.value.replace(",", ".")), rows[0].pack == 1);
															if (e.target.value.substring(e.target.value.length - 1) != "%") {
																e.target.value += " %";
															}
															e.target.blur();
														} else if (!e.ctrlKey && !e.metaKey && e.key.length == 1 && !(/\d+/.test(e.key) || /[,|.]/.test(e.key))) {
															e.preventDefault();
														}
													}}
												/>
											</label>
										</>
									);
								case 2:
									return (
										<>
											<label>
												{L10n.__("Comisión Stripe")}{" "}
												<input
													maxLength={4}
													min={0}
													max={100}
													className="percent-input"
													defaultValue={rows[0].stripe_commission_ratio + " %"}
													onFocus={(e) => {
														const m = e.target.value.trim().match(/([\d,.]+)/);
														if (m.length > 1) e.target.value = m[1];
														else e.target.value = "0";
														e.target.select();
													}}
													onKeyDown={(e) => {
														if (e.key == "Enter") {
															e.preventDefault();
															const m = e.target.value.trim().match(/([\d,.]+)/);
															if (m.length > 1) e.target.value = m[1];
															else e.target.value = "0";
															setStripeSharedRatio(rows[0].pack == 1 ? rows[0].pack_id : rows[0].negocio_id, parseFloat(e.target.value.replace(",", ".")), rows[0].pack == 1);
															if (e.target.value.substring(e.target.value.length - 1) != "%") {
																e.target.value += " %";
															}
															e.target.blur();
														} else if (!e.ctrlKey && !e.metaKey && e.key.length == 1 && !(/\d+/.test(e.key) || /[,|.]/.test(e.key))) {
															e.preventDefault();
														}
													}}
												/>
											</label>
										</>
									);
								case 3:
									return rows.reduce((acc, row) => acc + parseFloat(row.sub_pax), 0);
								case 4:
									return rows.reduce((acc, row) => acc + parseFloat(row.order_visits), 0) + "/" + rows.reduce((acc, row) => (row.total_visits < 0 ? acc : acc + parseFloat(row.total_visits)), 0);
								case 5:
									return Helper.FormatAmount(
										rows.reduce((acc, row) => acc + parseFloat(row.total_fee), 0) / 100,
										true,
										true
									);
								case 7:
									return Helper.FormatAmount(
										rows.reduce((acc, row) => acc + (row.negocio_fee * row.commission_ratio * 0.01) + (row.negocio_fee * row.stripe_commission_ratio * 0.01), 0) / 100,
										true,
										true
									);
								case 8:
									return Helper.FormatAmount(
										rows.reduce((acc, row) => acc + parseFloat(row.negocio_fee - row.negocio_fee * row.commission_ratio * 0.01 - row.negocio_fee * row.stripe_commission_ratio * 0.01), 0) / 100,
										true,
										true
									);
									return;
							}
						}
					}}
					renderGrandTotal={(columnIndex, rows) => {
						switch (columnIndex) {
							default:
								return null;
							case 3:
								return rows.reduce((acc, row) => acc + parseFloat(row.sub_pax), 0);
							case 4: {
								let seen = {};
								return (
									rows.reduce((acc, row) => acc + parseFloat(row.order_visits), 0) +
									"/" +
									rows.reduce((acc, row) => {
										const key = row.pack_id + " " + row.us_id + " " + row.user_id;
										if (row.pack && (seen[key] || row.total_visits < 0)) return acc;
										seen[key] = true;
										return acc + parseFloat(row.total_visits);
									}, 0)
								);
							}
							case 5: {
								let seen = {};
								return Helper.FormatAmount(
									rows.reduce((acc, row) => {
										const key = row.pack_id + " " + row.us_id + " " + row.user_id;
										if (row.pack == 1 && seen[key]) return acc;
										seen[key] = true;
										return acc + parseFloat(row.total_fee);
									}, 0) / 100,
									true,
									true
								);
							}
							case 7:
								return Helper.FormatAmount(
									rows.reduce((acc, row) => acc + (row.negocio_fee * row.commission_ratio * 0.01) + (row.negocio_fee * row.stripe_commission_ratio * 0.01), 0) / 100,
									true,
									true
								);
							case 8:
								return Helper.FormatAmount(
									rows.reduce((acc, row) => acc + (row.negocio_fee - row.negocio_fee * row.commission_ratio * 0.01 - row.negocio_fee * row.stripe_commission_ratio * 0.01), 0) / 100,
									true,
									true
								);
						}
					}}
					items={rows.map((row) => {
							return {
								data: row,
								columns: [
									row.negocio_name,
									row.user_name,
									<>
										{row.event_name} {row.pack == 1 ? <span className="tag">pack</span> : null}
									</>,
									row.sub_pax,
									row.order_visits + "/" + (row.total_visits < 0 ? "∞" : row.total_visits),
									Helper.FormatAmount(row.total_fee/100, true, true),
									<>
										{Helper.FormatISODate(row.start_date)}{" "}
										{row.cancelled == 1 ? (
											<span className="cancelation has-tooltip" data-tooltip="Renovación automática desactivada">
												C
											</span>
										) : row.renewal == 1 ? (
											<span className="renewal has-tooltip" data-tooltip="Es una renovación">
												R
											</span>
										) : null}
									</>,
									Helper.FormatAmount((row.negocio_fee * row.commission_ratio * 0.01 + row.negocio_fee * row.stripe_commission_ratio * 0.01) / 100, true, true),
									Helper.FormatAmount((row.negocio_fee - row.negocio_fee * row.commission_ratio * 0.01 - row.negocio_fee * row.stripe_commission_ratio * 0.01) / 100, true, true)
								]
							};
						})}
					renderDetails={(row) => {
						return row.extras?.map(item => {
							const amount = item.quantity * item.price * 0.01;
							const commission_ratio = (row.commission_ratio + row.stripe_commission_ratio) * 0.01;

							return (
								<div className="row" key={item.name + "-" + item.quantity + "-" + Math.random()}>
									<div style={{ width: "44.4%", paddingLeft: 15 }}>{item.name}</div>
									<div style={{ width: "11%" }}>{item.quantity}</div>
									<div style={{ width: "21.9%" }}>{Helper.FormatAmount(amount, true, true)}</div>
									<div style={{ width: "10.9%" }}>{Helper.FormatAmount(amount * commission_ratio, true, true)}</div>
									<div style={{ width: (100 * 2) / 18 + "%" }}>{Helper.FormatAmount(amount - amount * commission_ratio, true, true)}</div>
								</div>
							);
						});
					}}
				/>
			)}
		</div>
	);
}
