import React, { useCallback, useEffect, useRef, useState } from "react";
import HTMLReactParser from "html-react-parser";
import $ from 'jquery';

export default function Slider(props) {
    const stateRef = useRef({
        sliderInterval: null,
        currentIndex: 0
    });

    const slideDelay = props.delay || 4000;
    const slideSpeed = props.speed || 600;
    const sliderHeight = props.height || Math.max(0.55 * window.innerHeight, 520);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [imagesPreloaded, setImagesPreloaded] = useState(false);

    const animateSlides = useCallback(newIndex => {
        const slideWidth = $(".slider").outerWidth();
        $(".slides .slide").each(function(idx) {
            switch (props.effect) {
                default:
                case "slide":
                    $(this).css({
                        left: (idx - newIndex) * slideWidth
                    });
                    break;

                case "fade":
                    if (idx === newIndex) {
                        $(this).css({
                            opacity: 1,
                            zIndex: 1
                        });
                    } else {
                        $(this).css({
                            opacity: 0,
                            zIndex: -1
                        });
                    }
                    break;
            }
        });
    }, [props.effect]);

    const setupSlider = useCallback(() => {
        const slideWidth = $(".slider").outerWidth();
        const slideHeight = sliderHeight;
        const slideCount = $(".slides .slide").length;

        $(".slides .slide").each(function(idx) {
            $(this).css({
                position: "absolute",
                top: 0,
                width: slideWidth,
                height: slideHeight
            });
        });

        $(".slides").css({
            width: slideWidth * slideCount,
            height: slideHeight
        });

        animateSlides(stateRef.current.currentIndex);
    }, [animateSlides, sliderHeight]);

    useEffect(() => {
        for (let i=0; i<props.slides.length; i++) {
            if (props.slides[i].preloaded) {
                continue;
            }

            const slide_index = i;
            const image = new Image();
            image.onload = () => {
                props.slides[slide_index].preloaded = true;
                if (props.slides.reduce((acc, slide) => slide.preloaded ? acc : false, true)) {
                    setImagesPreloaded(true);
                }
            };
            image.src = props.slides[i].background;
        }
    }, [props.slides]);

    useEffect(() => {
        if (!imagesPreloaded) {
            return;
        }

        setCurrentIndex(0);

        window.addEventListener("resize", setupSlider);

        return function cleanUp() {
            window.removeEventListener("resize", setupSlider);
        }
    }, [imagesPreloaded, setupSlider]);

    useEffect(() => {
        stateRef.current.currentIndex = currentIndex;

        const slideCount = $(".slides .slide").length;
        setupSlider();
        animateSlides(currentIndex);

        clearInterval(stateRef.current.sliderInterval);

        stateRef.current.sliderInterval = setInterval(() => {
            const newIndex = (currentIndex + 1) % slideCount;
            setCurrentIndex(newIndex);
        }, slideDelay);

        return function cleanUp() {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearInterval(stateRef.current.sliderInterval);
        }
    }, [currentIndex, props.slides, animateSlides, setupSlider, slideDelay]);

    const slides = typeof props.slides === "string" ? JSON.parse(props.slides) : props.slides;

    if (!slides || !slides.map) {
        return null;
    };

    return (
        <div className={"slider " + (props.className || "")} style={props.style}>
            <div className="slides">
                {slides.map((slide, idx) => {
                    return (
                        <div key={idx} className={"slide" + (currentIndex === idx ? " active" : "")} style={{
                            backgroundImage: "url(" + (slide.background[0] == "/" ? slide.background : "/static/images/" + slide.background) + ")",
                            transition: slideSpeed + "ms"
                        }}>
                            <div className="slide-background-color">
                                <div className="slide-inner">
                                    <div>
                                        {slide.title && <h1 style={{
                                            color: slide.textColor || "inherit",
                                            transitionDelay: slideSpeed * 0.6 + "ms"
                                        }}>{HTMLReactParser(slide.title)}</h1>}
                                        {slide.subtitle && <h2 style={{
                                            color: slide.textColor || "inherit",
                                            transitionDelay: slideSpeed * 0.6 + "ms"
                                        }}>{HTMLReactParser(slide.subtitle)}</h2>}
                                        {slide.callToAction && slide.callToAction.href.trim() != "" && slide.callToAction.caption.trim() != "" && <div className="call-to-action" style={{
                                            transitionDelay: slideSpeed + "ms"
                                        }}><a target={slide.callToAction.target} className="btn btn-slider" href={slide.callToAction.href}>{slide.callToAction.caption}</a></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="thumbnails">
                {slides.map((slide, idx) => {
                    return (
                        <div key={idx} className={"thumbnail" + (currentIndex === idx ? " active" : "")} style={{backgroundImage: "url(" + (slide.background[0] == "/" ? slide.background : "/static/images/" + slide.background) + ")"}} onClick={e => {
                            setCurrentIndex(idx);
                        }} />
                    );
                })}
            </div>
        </div>
    );
}
