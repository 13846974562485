import { useState, useEffect } from "react";
import AccordionTab from "./AccordionTab";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import FormToggle from "./FormToggle";
import L10n from "../services/Locale";

export default function JSONEditor(props) {
	const [data, setData] = useState(props.initialValue || (props.associative ? {} : []));

	const onChange = props.onChange;

	useEffect(() => {
		if (onChange) onChange(data);
	}, [data]);

	if (typeof data === "string") {
		const newData = JSON.parse(data);
		if (newData) {
			setData(newData);
			return null;
		} else {
			console.error("Invalid data " + newData);
		}
	}

	if (props.associative) {
		const keys = Object.keys(data);
		keys.sort((a, b) => Math.sign(data[a].order - data[b].order));

		return <div className={"json-editor associative" + (props.className ? " " + props.className : "")}>
			{keys.map((itemKey, idx) => {
				const group = data[itemKey];
				return (
					<div className={itemKey} key={group.order + "-" + idx}>
						<AccordionTab
							heading={itemKey || L10n.__("Sin nombre")}
							rightSideElement={
								<>
									{!props.static && (
										<a
											href="#"
											className="minus"
											onClick={(e) => {
												e.preventDefault();
												const newData = { ...data };
												delete newData[itemKey];
												setData(newData);
											}}>
											<MaterialIcon name="delete" />
										</a>
									)}
								</>
							}>
							<div className="item-key">
								<Field
									type="string"
									placeholder={L10n.__("Indica el nombre de la categoría")}
									defaultValue={itemKey}
									onChange={(value) => {
										const newData = { ...data };
										newData[value] = data[itemKey];
										delete newData[itemKey];
										setData(newData);
									}}
								/>
							</div>
							{props.properties &&
								props.properties.map((property, idx) => {
									if (!group[property.key]) return null;

									return <Field
										maxLength={property.maxLength}
										key={property.key}
										optional={property.optional}
										defaultValue={group[property.key] || (property.type == number ? 0 : "")}
										min={property.minimumValue}
										type={property.type}
										title={property.title}
										recommendedWidth={property.recommendedWidth}
										recommendedHeight={property.recommendedHeight}
										maxFileSize={property.maxFileSize}
										fileFormats={property.fileFormats}
										onChange={(value) => {
											const newData = { ...data };
											newData[itemKey][property.key] = value;
											setData(newData);
										}}
									/>;
								})}
							<AccordionTab heading={props.listTitle}>
								<div className="json-list-edit">
									{group?.items &&
										group.items.map((item, idx) => {
											return (
												<div className="list-item" key={idx}>
													<AccordionTab
														heading={item[props.listProperties[0].key] || L10n.__("Sin nombre")}
														rightSideElement={
															<>
																{!props.static && (
																	<a
																		href="#"
																		className="minus"
																		onClick={(e) => {
																			e.preventDefault();
																			const newData = { ...data };
																			newData[itemKey].items.splice(idx, 1);
																			setData(newData);
																		}}>
																		<MaterialIcon name="delete" />
																	</a>
																)}
															</>
														}>
														{props.listProperties.map((listProp) => {
															return <div className={"list-col " + listProp.key + (listProp.halfColumn ? " half-column" : "") + (listProp.toggleable ? " toggleable" : "")} key={listProp.key}>
																{listProp.toggleable && <FormToggle defaultValue={item[listProp.key] != -1} onChange={value => {
																	const newData = { ...data };
																	if (value) {
																		if (newData[itemKey].items[idx][listProp.key] != -1) {
																			newData[itemKey].items[idx][listProp.key] = newData[itemKey].items[idx][listProp.key];
																			if (listProp.type == "number") {
																				newData[itemKey].items[idx][listProp.key] = parseInt(newData[itemKey].items[idx][listProp.key]);
																			}
																		} else {
																			newData[itemKey].items[idx][listProp.key] = listProp.type == "number" ? 0 : "";
																		}
																	} else {
																		newData[itemKey].items[idx][listProp.key] = -1;
																	}
																	setData(newData);
																}} />}
																<Field
																	maxLength={listProp.maxLength}
																	key={listProp.key}
																	defaultValue={item[listProp.key] || (listProp.type == "number" ? 0 : "")}
																	min={listProp.minimumValue}
																	type={listProp.type}
																	title={listProp.title}
																	recommendedWidth={listProp.recommendedWidth}
																	recommendedHeight={listProp.recommendedHeight}
																	maxFileSize={listProp.maxFileSize}
																	fileFormats={listProp.fileFormats}
																	onChange={(value) => {
																		const newData = { ...data };
																		newData[itemKey].items[idx][listProp.key] = value;
																		if (listProp.type == "number") {
																			newData[itemKey].items[idx][listProp.key] = parseInt(newData[itemKey].items[idx][listProp.key]);
																		}
																		setData(newData);
																	}}
																/>
															</div>;
														})}
													</AccordionTab>
												</div>
											);
										})}
									{!props.static && (
										<a
											href="#"
											className="plus plus-wide"
											onClick={(e) => {
												e.preventDefault();
												const newData = { ...data };
												const blankItem = {};
												for (let listProp of props.listProperties) {
													blankItem[listProp.key] = listProp.toggleable ? -1 : "";
												}
												newData[itemKey].items.push(blankItem);
												setData(newData);
											}}>
											<MaterialIcon name="add_circle" />
										</a>
									)}
								</div>
							</AccordionTab>
						</AccordionTab>
					</div>
				);
			})}
		{
			!props.static && (
				<a
					href="#"
					className={"plus plus-wide" + (data[""] ? " disabled" : "")}
					onClick={(e) => {
						e.preventDefault();
						const newData = { ...data };
						newData[""] = {
							order: keys.length == 0 ? 0 : data[keys[keys.length - 1]].order + 1,
							items: []
						};
						props.properties.forEach((property) => {
							newData[""][property.key] = "";
						});
						setData(newData);
					}}>
					<MaterialIcon name="add_circle" />
				</a>
			)
		}
		</div>
	}

	return (
		<div className={"json-editor simple" + (props.className ? " " + props.className : "")}>
			<div className="json-list-edit">
				{data.map((item, idx) => {
					const heading = (props.headingPropertyKey ? item[props.headingPropertyKey] : item[props.properties[0].key]) || L10n.__("Sin título");
					return (
						<AccordionTab
							key={"accordion" + idx}
							heading={heading}
							className={heading.toLowerCase().replaceAll(" ", "-")}
							rightSideElement={
								<>
									{!props.static && (
										<a
											href="#"
											className="minus"
											onClick={(e) => {
												e.preventDefault();
												const newData = [...data];
												newData.splice(idx, 1);
												setData(newData);
											}}>
											<MaterialIcon name="delete" />
										</a>
									)}
								</>
							}>
							{props.properties.map((listProp) => {
								return (
									<div className={"list-col " + listProp.key} key={listProp.key}>
										<Field
											maxLength={listProp.maxLength}
											key={listProp.key}
											defaultValue={item[listProp.key] || (listProp.type == "number" ? 0 : "")}
											min={listProp.minimumValue}
											type={listProp.type}
											title={listProp.title}
											recommendedWidth={listProp.recommendedWidth}
											recommendedHeight={listProp.recommendedHeight}
											maxFileSize={listProp.maxFileSize}
											fileFormats={listProp.fileFormats}
											onChange={(value) => {
												const newData = [...data];
												newData[idx][listProp.key] = value;
												setData(newData);
											}}
										/>
									</div>
								);
							})}
						</AccordionTab>
					);
				})}
				{!props.static && (
					<a
						href="#"
						className="plus plus-wide"
						onClick={(e) => {
							e.preventDefault();
							const newData = [...data];
							const blankItem = {};
							for (let listProp of props.properties) {
								blankItem[listProp.key] = "";
							}
							newData.push(blankItem);
							setData(newData);
						}}>
						<MaterialIcon name="add_circle" />
					</a>
				)}
			</div>
		</div>
	);
}
