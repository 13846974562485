import { useState, useEffect, useRef } from "react";
import AdminService from "../services/Admin";
import LoadingIndicator from "./LoadingIndicator";
import Modal from "./Modal";
import ImageUpload from "./ImageUpload";
import Field from "./Field";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";

export default function EditorSlider(props) {
    const [config, setConfig] = useState();
    const [currentSlide, setCurrentSlide] = useState(0);
    const modalBoton = useRef();

    const slug = props.slug;
    const onChange = props.onChange;

    function moveSlide(offset) {
        setCurrentSlide(Math.max(0, Math.min(config.length - 1, currentSlide + offset)));
    }

    function newSlide() {
        const newConfig = [...config];
        newConfig.push({title: "", subtitle: "", background: "", textColor: "", callToAction: { caption: "", href: "" }});
        setConfig(newConfig);
        setCurrentSlide(newConfig.length - 1);
    }

    function deleteSlide(index) {
        const newConfig = [...config];
        newConfig.splice(index, 1);
        setConfig(newConfig);
        if (currentSlide >= newConfig.length || currentSlide < 0) {
            setCurrentSlide(Math.max(0, Math.min(newConfig.length - 1, currentSlide)));
        }
    }

    function animateSlides(current) {
        $(".slides-container .slide").css({
            width: $(".slides-container").width()
        });

        $(".slides-container .inner").css({
            left: -current * $(".slides-container").width()
        });
    }

    useEffect(() => {
        AdminService.GetContent(slug).then(data => {
            if (data?.length && data[0].type == "json") {
                const config = JSON.parse(data[0].content);
                for (let i=0; i<config.length; i++) {
                    if (!config[i].callToAction) {
                        config[i].callToAction = { caption: "", href: "" };
                    }
                }
                setConfig(config);
            }
        });
    }, [slug]);

    useEffect(() => {
        $(".slides-container").css({
            height: $(".slides-container .slide").height()
        });

        $(".slides-container .slide").css({
            width: $(".slides-container").width()
        });

        if (config && config.length) {
            $(".slides-container .inner").css({
                width: $(".slides-container").width() * config.length,
                position: "absolute",
                left: 0
            });

            animateSlides(currentSlide);
        }
    }, [config, currentSlide]);

    useEffect(() => {
	    if (onChange) onChange(config);
    }, [config]);

    if (!config) return <LoadingIndicator />;

    return <div className="editor-slider">
        <div className="slides-container">
            <div className="inner">
                {config.map((slide, idx) => {
                    return <div className="slide" key={slide.title}>
                        {slide.textColor == "white" && <div className="overlay" />}
                        <ImageUpload limitsInfoPosition="bottom-right" recommendedWidth={1440} recommendedHeight={520} maxFileSize={600 * 1024} height={450} sizeMode="cover" initialValue={slide.background} onChange={value => {			    
			    const newConfig = [...config];
			    newConfig[idx].background = value;
			    setConfig(newConfig);
                        }}/>
                        <Field className="title" type="string" style={{color: slide.textColor || "black"}} defaultValue={slide.title} onChange={value => {
			    const newConfig = [...config];
			    newConfig[idx].title = value;
			    setConfig(newConfig);
                        }}/>
                        <Field className="subtitle" type="string" style={{color: slide.textColor || "black"}} label={L10n.__("Subtítulo")} defaultValue={slide.subtitle} onChange={value => {
			    const newConfig = [...config];
			    newConfig[idx].subtitle = value;
			    setConfig(newConfig);
                        }}/>
                        <a href="#" className={"color-toggle color-" + config[idx].textColor} onClick={e => {
                            e.preventDefault();
                            const newConfig = [...config];
                            newConfig[idx].textColor = newConfig[idx].textColor == "white" ? "black" : "white";
                            setConfig(newConfig);
                        }}><div style={{position: "relative"}}><div className="icon-tooltip">{L10n.__("Invertir colores")}</div><MaterialIcon name="invert_colors" /></div></a>
                        <a href="#" style={{ backgroundColor: slide.textColor, color: slide.textColor == "white" ? "black" : "white" }} className="cta-button" onClick={e => {
                            e.preventDefault();
                            modalBoton.current.setState({
                                caption: slide.callToAction.caption,
                                href: slide.callToAction.href,
                                slideIndex: idx
                            });
                            modalBoton.current.open();
                        }}>{slide.callToAction.caption || L10n.__("Añadir botón")}</a>
                    </div>;
                })}
            </div>
            <div className="minimap">
                {config.map((slide, idx) => {
                    return <div className={(currentSlide == idx ? "active " : "") + "thumbnail"} onClick={e => {
                        e.preventDefault();
                        setCurrentSlide(idx);
                    }} key={slide.title} style={{ backgroundImage: "url(" + (slide.background[0] == "/" ? slide.background : "/static/images/" + slide.background) + ")" }}>
                        <a href="#" onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteSlide(idx);
                        }} className="delete-button"><MaterialIcon name="close" /></a>
                    </div>;
                })}
                <a href="#" onClick={e => {
                    e.preventDefault();
                    newSlide();
                }} className="new-button"><MaterialIcon name="add" /></a>
            </div>
        </div>

        <Modal
            ref={ref => modalBoton.current = ref}
            title={L10n.__("Editar botón")}
            className="modal-boton"
            renderContent={modal => {
                return <div className="modal-editar-boton">
                    <Field type="string" defaultValue={modal.state.caption} title={L10n.__("Texto del botón")} onChange={value => {
                        modal.setState({ caption: value });
                    }} />
                    <Field type="string" placeholder="https://..." defaultValue={modal.state.href} title={L10n.__("Enlace de destino")} onChange={value => {
                        modal.setState({ href: value });
                    }} />
                    <a href="#" className="boton-guardar btn" onClick={e => {
                        e.preventDefault();
                        const newConfig = [...config];
                        newConfig[modal.state.slideIndex].callToAction = {
                            caption: modal.state.caption,
                            href: modal.state.href
                        };
                        setConfig(newConfig);
                        modal.close();
                    }}>{L10n.__("Guardar")}</a>
                </div>;
            }}
        />
    </div>
}
