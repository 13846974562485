import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import L10n from "../services/Locale";
import Helper from "../services/Helper";
import SubscriptionsService from "../services/Subscriptions";
import { SessionContext } from "../context/SessionContext";
import { APIRequest } from "../services/API";
import Slider from "./Slider";
import LoadingIndicator from "./LoadingIndicator";
import Session from "../services/Session";
import MaterialIcon from "./MaterialIcon";
import { useStripe, useElements, CardElement, Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Settings from "../services/Settings";


export default function PagoSuscripcion(props) {
    const [ total, setTotal ] = useState(0);
    const [ subscriptionName, setSubscriptionName ] = useState("");
    const [ subscriptionTitle, setSubscriptionTitle ] = useState("");
    const [ subscriptionSubtitle, setSubscriptionSubstitle ] = useState("");
    const [ slides, setSlides ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ subscriptionPax, setSubscriptionPax ] = useState(1);
    const [ pax, setPax ] = useState(1);
    const [ maxPax, setMaxPax ] = useState(Infinity);
    const [ subscriptionFee, setSubscriptionFee ] = useState(0);
    const [ stripePromise, setStripePromise ] = useState(null);
    const [ userSubscription, setUserSubscription ] = useState(null);
    const [ paxChangeInProgress, setPaxChangeInProgress ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ stockHeld, setStockHeld ] = useState(false);
    const [ timeLeft, setTimeLeft ] = useState(-1);
    const heldStockTimer = useRef();

	const navigate = useNavigate();
    const cartData = SubscriptionsService.GetCartData();
    const sessionContext = useContext(SessionContext);

    useEffect(() => {
        if (pax < 1) {
            setPax(1);
            return;
        }

        if (subscriptionPax != -1 && pax > subscriptionPax) {
            setPax(Math.max(1, subscriptionPax));
            return;
        }

        setTotal(subscriptionFee * pax);
    }, [ pax, subscriptionFee, subscriptionPax ]);

    useEffect(() => {
        if (!userSubscription) return;

        let q = [...paxChangeInProgress];
        q.push(true);
        setPaxChangeInProgress(q);
        APIRequest("subscriptions/update-subscription-pax-checkout", { user_subscription_id: userSubscription.id, pax }).then(() => {
            let q = [...paxChangeInProgress];
            q.pop();
            setPaxChangeInProgress(q);
        });

        APIRequest("subscriptions/hold-stock", { user_subscription_id: userSubscription.id }).then(response => {
            if (!response.status) {
                setErrorMessage(L10n.__("La suscripción seleccionada ya no está disponible."));
                setStockHeld(false);
            } else {
                setStockHeld(true);
            }

            if (!isNaN(parseInt(response.data))) setMaxPax(response.data == - 1 ? Infinity : parseInt(response.data));

            if (response.data != -1) {
                const checkHeldStock = () => {
                    APIRequest("subscriptions/check-held-stock", { user_subscription_id: userSubscription.id }).then(response => {
                        if (!response.status) {
                            setErrorMessage(L10n.__("La suscripción seleccionada ya no está disponible."));
                            setStockHeld(false);
                        } else {
                            setStockHeld(true);
                            if (response.data > 0) {
                                clearTimeout(heldStockTimer.current);
                                heldStockTimer.current = setTimeout(checkHeldStock, Math.min(parseInt(response.data) * 1000 + 1000, 30000));
                            }
                        }

                        setTimeLeft(parseInt(response.data));
                    });
                };
                
                clearTimeout(heldStockTimer.current);
                heldStockTimer.current = setTimeout(checkHeldStock, 1000);
            }
        });
    }, [pax, userSubscription]);

    useEffect(() => {
        if (stripePromise === null) return;

        Session.CheckIsLoggedIn().then(loggedIn => {
			if (!loggedIn) {
				Session.RequireAccount("", navigate);
				return;
			}

            if (cartData?.typeMode == SubscriptionsService.SUBSCRIPTION_MODE_TIPO) {
                APIRequest("subscriptions/get-type-by-slug", { slug: cartData.param }).then(async (response) => {
                    setSubscriptionTitle(response.data.name);
                    setSubscriptionName(response.data.name);
                    setSubscriptionSubstitle(response.data.short_description);
                    setTotal(response.data.price * response.data.pax);
                    setSubscriptionPax(response.data.pax);
                    setSubscriptionFee(response.data.price);
                    setMaxPax(response.data.stock);
                    setPax(1);

                    const subscriptionResponse = await APIRequest("subscriptions/get-user-type-subscription", { subscription_type_slug: cartData.param, pax: response.data.pax });

                    setUserSubscription(subscriptionResponse.data);

                    if (!subscriptionResponse.status) {
                        setErrorMessage(L10n.__("La suscripción seleccionada ya no está disponible."));
                    }

                    APIRequest("subscriptions/get-events-for-type", { subscription_type_id: response.data.id }).then(response => {
                        const newSlides = [...slides];
                        for (let negocio of response.data) {
                            let foundEventImage = false;
                            for (let event of negocio.events) {
                                if (event.image_url) {
                                    newSlides.push({
                                        background: event.image_url,
                                        title: event.nombre
                                    });
                                    foundEventImage = true;
                                }
                            }

                            if (!foundEventImage) {
                                newSlides.push({
                                    background: negocio.image_url,
                                    title: negocio.name
                                });
                            }
                        }
                        setSlides(newSlides);
                    });
                });
            } else if (cartData.typeMode == SubscriptionsService.SUBSCRIPTION_MODE_EVENTO) {
                APIRequest("events/get", { id: cartData.param }).then(async (response) => {
                    const newSlides = [...slides];

                    setSubscriptionName(response.data.nombre);
                    setSubscriptionTitle(response.data.nombre + '<br /><span style="color:grey;font-weight:400">' + response.data.negocio.name + '</span>');
                    setSubscriptionSubstitle(response.data.description);
                    setTotal(response.data.subscription_data.price * response.data.subscription_data.pax);
                    setSubscriptionPax(response.data.subscription_data.pax);
                    setPax(1);
                    setSubscriptionFee(response.data.subscription_data.price);
                    setMaxPax(response.data.stock);

                    const subscriptionResponse = await APIRequest("subscriptions/get-user-event-subscription", { event_id: cartData.param, pax: response.data.subscription_pax ?? 1, type_slug: cartData.typeSlug });

                    setUserSubscription(subscriptionResponse.data);

                    if (!subscriptionResponse.status) {
                        setErrorMessage(L10n.__("La suscripción seleccionada ya no está disponible."));
                    }

                    newSlides.push({
                        background: response.data.image_url,
                        title: response.data.name
                    });

                    setSlides(newSlides);
                });
            }
        });
    }, [ stripePromise ]);

    useEffect(() => {
        setLoading(!(userSubscription != null && userSubscription != false && userSubscription.setup_intent_client_secret));
    }, [ userSubscription ]);

    useEffect(() => {
        setLoading(true);

        if (stripePromise === null) {
            Settings.Get("STRIPE_PUBLIC").then(key => {
                setStripePromise(loadStripe(key, { locale: L10n.GetLocale() }));
            });
        }
    }, []);

    if (errorMessage) return (<div className="error-container">
        <div className="error-standalone">{errorMessage}<div><a className="btn btn-brown" href="/">{L10n.__("Volver")}</a></div></div>     
    </div>)

    if (loading) return <LoadingIndicator />;

    return <div className="pago-suscripcion" style={{ opacity: 0 }}>
        <div className="main">
            <div className="order-summary">
                <div className="title">{sessionContext.session.name}, {L10n.__("completa tu suscripción")}</div>
                {timeLeft > 0 && <div className="time-left">{L10n.__("Estamos guardando tus plazas hasta las")} {(new Date(Date.now() + timeLeft * 1000)).toLocaleTimeString(L10n.GetLocale(), { timeStyle: "short" })}.</div>}
                <div className="subscription-info">
                    <div className="subscription-title" dangerouslySetInnerHTML={{ __html: subscriptionTitle }} />
                    <div className="additional-info">
                        <div><MaterialIcon name="calendar_month" /> {Helper.FormatDate(new Date())}</div>
                        <div><MaterialIcon name="account_circle" /> {pax}</div>
                        <div><MaterialIcon name="credit_card" /> {Helper.FormatAmount(total / 100.0, false, true)}/{L10n.__("mes")}</div>
                    </div>
                    <div className="subscription-subtitle" dangerouslySetInnerHTML={{ __html: subscriptionSubtitle }} />
                </div>

                <div className="total-container">
                    <div className="pax-selector">
                        <div className="pax">{pax} {pax == 1 ? L10n.__("persona") : L10n.__("personas")}</div>
                        <a href="#" className="pax-button minus" onClick={e => {
                            e.preventDefault();
                            setPax(Math.max(1, pax - 1));
                        }}><MaterialIcon name="remove" /></a>
                        <a href="#" className="pax-button plus" onClick={e => {
                            e.preventDefault();
                            let newPax = pax + 1;
                            if (subscriptionPax != -1 && newPax > subscriptionPax) newPax = subscriptionPax;
                            setPax(Math.max(1, Math.min(maxPax, newPax)));
                        }}><MaterialIcon name="add" /></a>
                    </div>
                    <div className="total">
                        <div>{L10n.__("Total (IVA inc)")}</div>
                        <div>{Helper.FormatAmount(total / 100.0, false, true)}/{L10n.__("mes")}</div>
                    </div>
                </div>
            </div>

            <div className="stripe-widget-container">
                <Elements stripe={stripePromise} options={{ clientSecret: userSubscription.setup_intent_client_secret }} >
                    <StripeCheckoutForm chargedAmount={total} subscriptionName={subscriptionName} returnURL="/suscripcion-completada" />
                </Elements>
            </div>

        </div>
        <div className="sidebar">
            {slides?.length > 0 && <div className="slider-container">
                <Slider
                    style={{
                        overflow: "hidden",
                        height: 200,
                        minHeight: 200
                    }}
                    height={200}
                    slides={slides}
                />
            </div>}
            <div className="discount-box">
                <div className="discount-box-title">{L10n.__("¿Tienes un código de descuento?")}</div>
                <input type="text" />
                <button className="btn btn-brown aplicar-codigo-descuento">{L10n.__("Aplicar")}</button>
            </div>
        </div>

        {errorMessage != "" && <div className="error-container">
            <div className="error">{errorMessage}</div>     
        </div>}
    </div>;
}
