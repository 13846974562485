import { useEffect, useState, useContext, useRef } from "react";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";
import Helper from "../services/Helper";

export default function SubscriptionTypesBar(props) {
	const [activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex] = useState(props.initialValue ?? 0);
	const [elementID, _setElementID] = useState("subt-" + Math.floor(Math.random() * 10000 + 10000));
	const [floating, setFloating] = useState(true);
	const [rightEnd, setRightEnd] = useState(false);

	const scrollOffset = useRef();

	const [currentBarIndex, setCurrentBarIndex] = useState(0);

	const subscriptionsContext = useContext(SubscriptionsContext);
	const subscriptionTypes = props.subscriptionTypes || subscriptionsContext.subscriptionTypes;

	const barScrollAnimationRef = useRef({
		inProgress: false,
		targetOffsetX: 0
	});

	function animateBarScroll(offsetX) {
		const bar = document.querySelector(".subscription-types-bar");

		const onFrame = () => {
			if (!bar) return;

			bar.scrollLeft += (barScrollAnimationRef.current.targetOffsetX - bar.scrollLeft) / 5.0;
			if (Math.abs(Math.floor(offsetX - bar.scrollLeft)) < 10) barScrollAnimationRef.current.inProgress = false;

			if (barScrollAnimationRef.current.inProgress) requestAnimationFrame(onFrame);
		};

		barScrollAnimationRef.current.inProgress = false;

		setTimeout(() => {
			barScrollAnimationRef.current.inProgress = true;
			barScrollAnimationRef.current.targetOffsetX = offsetX;
			requestAnimationFrame(onFrame);
		}, 10);
	}

	useEffect(() => {
		let headerHeight = window.innerWidth <= 992 ? $("header.mobile").outerHeight(true) : $("header.desktop").outerHeight(true);
		$("body").css({ paddingTop: headerHeight + $("#" + elementID).outerHeight(true) });
		$("#" + elementID).css({
			top: headerHeight
		});

		return () => {
			$("body").css({ paddingTop: "" });
		};
	}, []);

	const changeCurrentBarIndex = (index) => {
		setCurrentBarIndex(Math.max(0, Math.min(subscriptionTypes.length - 1, index)));
	};

	useEffect(() => {
		const element = document.getElementById(elementID);
		const $container = $(element).find(".subscription-types-container");
		const $bar = $(element).find(".subscription-types-bar");
		$(element).css({ height: $container.outerHeight(true) + (window.innerWidth < 992 ? 19 : 35) });
		$bar.css({ height: $container.outerHeight(true) });
		$container.css({ position: "absolute" });

		if (!(subscriptionTypes[activeSubscriptionTypeIndex].available && subscriptionsContext.userSubscriptions.filter((t) => t.subscription_type_id == subscriptionTypes[activeSubscriptionTypeIndex].id).length > 0)) {
			let firstSubscribed = -1;
			subscriptionTypes.forEach((subscriptionType, idx) => {
				if (firstSubscribed != -1) return;

				const subscribed = subscriptionType.available && subscriptionsContext.userSubscriptions.filter((t) => t.subscription_type_id == subscriptionType.id).length > 0;
				if (subscribed) {
					firstSubscribed = idx;
				}
			});

			if (firstSubscribed != -1) {
				if (props.onChange) props.onChange(firstSubscribed);
				setActiveSubscriptionTypeIndex(firstSubscribed);
			}
		}

		let elementsWidth = 0;
		$("#" + elementID + " .subscription-types-container > div").each(function (idx) {
			elementsWidth += $(this).outerWidth(true);
		});

		const maxOffset =
			$("#" + elementID + " .subscription-types-container > div")
				.last()[0]
				.getBoundingClientRect().left -
			$("#" + elementID + " .subscription-types-container")[0].getBoundingClientRect().left +
			$("#" + elementID + " .subscription-types-container > div")
				.last()[0]
				.getBoundingClientRect().width -
			$("#" + elementID + " .subscription-types-bar")[0].getBoundingClientRect().width;

		let offset = 0;
		$("#" + elementID + " .subscription-types-container > div").each(function (idx) {
			if (idx + 1 < currentBarIndex) {
				offset += $(this).outerWidth(true);
			}
		});

		scrollOffset.current = Math.min(0, -Math.min(offset, maxOffset));
		setRightEnd(scrollOffset.current == -maxOffset);

		$("#" + elementID + " .subscription-types-container").css({ left: scrollOffset.current });
	}, [currentBarIndex, subscriptionTypes]);

	useEffect(() => {
		$("html, body").animate({ scrollTop: 0 }, 500);
	}, [activeSubscriptionTypeIndex]);

	useEffect(() => {
		document.body.classList.add("subscriptions-bar-visible");

		$("#subscription-types-bar-injected-css").remove();

		if (Helper.IsResponsive()) {
			$("head").append(`
                <style id="subscription-types-bar-injected-css">
                .subscription-types-bar-container.floating {
                    top: ${$("header.mobile").outerHeight(true)}px;
                }
                </style>
            `);
		}

		return () => {
			$("#subscription-types-bar-injected-css").remove();
			document.body.classList.remove("subscriptions-bar-visible");
		};
	}); // On render.

	return (
		<div id={elementID} className={"subscription-types-bar-container " + (floating ? " floating" : "") + (currentBarIndex == 0 ? " left-end" : "") + (rightEnd ? " right-end" : "")}>
			<div className="subscription-types-bar">
				<MaterialIcon
					name="expand_circle_right"
					className="move-left"
					onClick={(e) => {
						e.preventDefault();
						changeCurrentBarIndex(currentBarIndex - 6);
					}}
				/>
				<div className="subscription-types-container">
					{subscriptionTypes?.map((type, idx) => {
						let subscribed = type.available && subscriptionsContext.userSubscriptions.filter((t) => t.subscription_type_id == type.id).length > 0;

						return (
							<div
								className={"subscription-type" + (subscribed ? " subscribed" : "") + (activeSubscriptionTypeIndex == idx ? " active" : "")}
								key={"subscription-type-" + idx}
								onClick={(e) => {
									if (activeSubscriptionTypeIndex == idx) return;
									if (props.onChange) props.onChange(idx);
									setActiveSubscriptionTypeIndex(idx);
								}}>
								<div className="image" style={{ backgroundImage: "url(" + type.image_url + ")" }} />
								<div className="name">{type.name}</div>
								{type.pack == 1 && (
									<div className="pack-tag inhibit-clicks has-tooltip" data-tooltip={L10n.__("Esta suscripción te permite disfrutar de toda la oferta de") + " " + type.name}>
										{L10n.__("Pack")}
									</div>
								)}
							</div>
						);
					})}
				</div>
				<MaterialIcon
					name="expand_circle_right"
					className="move-right"
					onClick={(e) => {
						e.preventDefault();
						changeCurrentBarIndex(currentBarIndex + 6);
					}}
				/>
			</div>
		</div>
	);
}
