import React, { Component, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Orders from "../services/Orders";
import Helper from "../services/Helper";
import $ from "jquery";

import L10n from "../services/Locale";
import Icon from "./Icon.jsx";
import MaterialIcon from "./MaterialIcon.jsx";
import Session from "../services/Session.jsx";
import { Content } from "./Content.jsx";
import ContentHelper from "../services/ContentHelper.jsx";
import { APIRequest } from "../services/API.js";
import TPVWidget from "./TPVWidget.jsx";
import Slider from "./Slider.jsx";
import LoadingIndicator from "./LoadingIndicator.jsx";
import Settings from "../services/Settings";
import Events from "../services/Events.jsx";
import SubscriptionsService from "../services/Subscriptions";
import { SubscriptionsContext } from "../context/SubscriptionsContext.jsx";

export default function Pago(props) {
	const subscriptionsContext = useContext(SubscriptionsContext);

	const [orderDetails, setOrderDetails] = useState(null);
	const [orderError, setOrderError] = useState(null);
	const [orderCompleted, setOrderCompleted] = useState(false);
	const [orderInProgress, setOrderInProgress] = useState(true);
	const [orderData, setOrderData] = useState({ comments: [] });
	const [userName, setUserName] = useState("");
	const [userSurname, setUserSurname] = useState("");
	const [event, setEvent] = useState(null);
	const [booking, setBooking] = useState(null);
	const [placeholderCampoAlergias, setPlaceholderCampoAlergias] = useState("");
	const [codigoDescuento, setCodigoDescuento] = useState(null);
	const [codigoDescuentoInput, setCodigoDescuentoInput] = useState();
	const [errorCodigoDescuento, setErrorCodigoDescuento] = useState(null);
	const [loadingInProgress, setLoadingInProgress] = useState(false);
	const [logoHeight, setLogoHeight] = useState(65);
	const [subscriptionsEnabled, setSubscriptionsEnabled] = useState(false);
	const [acceptTermsError, setAcceptTermsError] = useState(false);

	useEffect(() => {
		Settings.Get("LOGO_HEIGHT", 65).then((height) => {
			setLogoHeight(parseInt(height));
		});

		SubscriptionsService.IsEnabled().then((enabled) => {
			setSubscriptionsEnabled(enabled);
		});

		ContentHelper.GetString("placeholder-campo-alergias").then((text) => {
			setPlaceholderCampoAlergias(text);
		});

		Promise.all([APIRequest("subscriptions/get-user-subscriptions"), Session.GetSession()]).then(([userSubscriptionsResponse, session]) => {
			const userSubscriptions = userSubscriptionsResponse.data;
			let event = Session.GetBookingData()?.selected_event;

			if (!event) {
				location.href = "/";
				return;
			}

			event = JSON.parse(event);

			if (session && session.id) {
				if (userSubscriptions?.availabilityIDs?.indexOf(Session.GetBookingData()?.selected_availability_id) != -1) {
					setUserName(session["name"]);
					setUserSurname(session["surname"]);
				} else {
					SubscriptionsService.RequireSubscriptionForAvailability(Session.GetBookingData()?.selected_availability_id);
				}
			} else {
				Session.RequireLogin();
			}
		});

		$("header").addClass("simple");
		let booking = Session.GetBookingData();
		let event = booking.selected_event;

		if (event && booking) {
			event = JSON.parse(event);
			Session.SetBookingData("selected_event_id", event.id);
			const bookingData = Session.GetBookingData();

			if (!bookingData.selected_slot) {
				window.location.href = "/venue/" + event.slug;
				return;
			}

			setEvent(event);
			setBooking(bookingData);
			setOrderError(null);
			setOrderInProgress(false);
		} else {
			setOrderError(null);
			setOrderInProgress(false);

			window.location.href = "/";
		}

		getOrderDetails();

		const onScrollEvent = (_e) => {
			if ($(".booking-sidebar").length == 0) return;

			if ($(window).scrollTop() > 14) {
				if (!$(".booking-sidebar").hasClass("floated")) {
					$(".booking-sidebar")
						.css({
							position: "fixed",
							top: $("header .logo").height() + 20 + "px",
							left: $(".booking-sidebar").offset().left + "px",
							width: $(".booking-sidebar").outerWidth()
						})
						.addClass("floated");
				}
			} else {
				if ($(".booking-sidebar").hasClass("floated")) {
					$(".booking-sidebar")
						.css({
							position: "",
							top: "",
							left: "",
							width: ""
						})
						.removeClass("floated");
				}
			}
		};

		window.addEventListener("scroll", onScrollEvent);

		return () => {
			window.removeEventListener("scroll", onScrollEvent);
			$("header").removeClass("simple");
		};
	}, []);

	let width = 0;
	$(".preorder-details-line:not(.secondary) .preorder-quantity").each(function () {
		if ($(this).width() > width) {
			width = $(this).width();
		}
	});
	$(".preorder-details-line:not(.secondary) .preorder-quantity").width(width);

	const getOrderDetails = () => {
		return new Promise((resolve, reject) => {
			const selected_event = JSON.parse(Session.GetBookingData("selected_event"));
			Session.SetBookingData("selected_full_slot", Helper.RenderFullTimeSlot(Session.GetBookingData("selected_slot"), selected_event.mean_lunch_time, selected_event.mean_dining_time, selected_event));

			Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
				if (response.status) {
					const order_completed = response.data.order && response.data.order.payment_status === "1";
					const codigoDescuento = response.data.order ? parseInt(response.data.order.discount_id) : null;

					if (response.data.order) {
						if (!response.data.order.comments) response.data.order.comments = [];
						if (typeof response.data.order.comments === "string") response.data.order.comments = JSON.parse(response.data.order.comments || "[]") || [];
					}

					setOrderDetails(response.data);
					setOrderCompleted(order_completed);
					setEvent(response.data.event);
					setOrderData(response.data.order);
					setCodigoDescuento(isNaN(codigoDescuento) ? null : codigoDescuento);
					setOrderInProgress(false);

					if (order_completed) {
						window.location.href = "/reserva-completada";
						return;
					}

					if (!response.data.order || !parseInt(sessionStorage.getItem("current_order_id")) || sessionStorage.getItem("current_order_id") == 0) {
						APIRequest("payment/create-order", Session.GetBookingData()).then((response) => {
							sessionStorage.setItem("current_order_id", response.data);
							Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
								const codigoDescuento = parseInt(response.data.order.discount_id);
								setOrderDetails(response.data);
								setOrderData(response.data.order);
								setCodigoDescuento(isNaN(codigoDescuento) ? null : codigoDescuento);
								resolve(response.data.order);
							});
						});
					} else {
						resolve(response.data.order);
					}
				} else {
					setOrderError(response.data.error);
					setOrderInProgress(false);
					reject(response.data.error);
				}
			});
		});
	};

	const renderBookingSidebar = () => {
		if (orderError) return null;

		const hasSlider = orderDetails.order.extra_events && orderDetails.order.extra_events.length > 0;
		let slides = [];

		if (hasSlider) {
			const url = orderDetails.event.image_url || orderDetails.event.event_image_url;
			slides.push({
				background: url[0] == "/" ? url : "eventos/" + url,
				title: orderDetails.event.negocio.name
			});
			orderDetails.order.extra_events.forEach((item) => {
				slides.push({
					background: item.image_url[0] == "/" ? item.image_url : "eventos/" + item.image_url,
					title: item.name
				});
			});
		}

		const image = orderDetails.event.image_url || orderDetails.event.event_image_url;

		return (
			<div className="col-md-4 booking-sidebar">
				<div className="box event-information">
					{!hasSlider && (
						<div
							className="event-image"
							style={{
								backgroundImage: "url(" + (image[0] == "/" ? image : "/static/images/eventos/" + image) + ")"
							}}
						/>
					)}
					{hasSlider && (
						<Slider
							style={{
								overflow: "hidden",
								width: 300,
								height: 200,
								minHeight: 200
							}}
							height={200}
							slides={slides}
						/>
					)}
					<div className="booking-information">
						<div className="change-order-link">
							<Link to={subscriptionsContext.subscriptionsEnabled ? "/suscripcion/" + SubscriptionsService.GetCartData()["typeSlug"] + "/" + event.slug : "/venue/" + event.slug}>{L10n.__("¿Quieres modificar tu reserva?")}</Link>
						</div>
					</div>
				</div>

				{!!subscriptionsContext.subscriptionsEnabled && renderCajaDescuento()}
			</div>
		);
	};

	const onGotOrderId = (order_id) => {
		if (!orderDetails.order) {
			getOrderDetails();
		} else {
			setOrderDetails({ ...orderDetails, order: { ...orderDetails.order, id: order_id } });
		}
	};

	const renderCajaDescuento = () => {
		return (
			<div className="box caja-descuento">
				{codigoDescuento != null && codigoDescuento !== 0 && (
					<React.Fragment>
						<div>{L10n.__("¡Código descuento aplicado!")}</div>
						<button
							className="btn aplicar-codigo-descuento"
							onClick={(e) => {
								APIRequest("payment/unapply-discount-code", {
									order_id: orderData.id
								}).then((_response) => {
									setCodigoDescuento(null);
									setCodigoDescuentoInput(null);
									setErrorCodigoDescuento(null);
									setLoadingInProgress(true);
									getOrderDetails().then(() => {
										setLoadingInProgress(false);
									});
								});
							}}>
							Eliminar descuento
						</button>
					</React.Fragment>
				)}
				{(codigoDescuento === null || codigoDescuento === 0) && (
					<React.Fragment>
						<div>{L10n.__("¿Tienes un código de descuento?")}</div>
						<input
							className="codigo-descuento"
							onChange={(e) => {
								setCodigoDescuentoInput(e.target.value);
							}}></input>
						{errorCodigoDescuento && <div className="error-codigo-descuento">{errorCodigoDescuento}</div>}
						<button
							className="btn aplicar-codigo-descuento"
							onClick={(_e) => {
								setLoadingInProgress(true);
								APIRequest("payment/apply-discount-code", {
									code: codigoDescuentoInput,
									order_id: orderData.id
								}).then((response) => {
									if (response.status) {
										setErrorCodigoDescuento(null);
										setCodigoDescuento(codigoDescuentoInput);
										getOrderDetails().then(() => {
											setLoadingInProgress(false);
										});
									} else {
										setErrorCodigoDescuento(L10n.__("Este código no parece válido."));
										setLoadingInProgress(false);
										setCodigoDescuento(null);
									}
								});
							}}>
							{L10n.__("Aplicar")}
						</button>
					</React.Fragment>
				)}
			</div>
		);
	};

	const completarReserva = () => {
		if (!$(".accept-terms").prop("checked")) {
			setAcceptTermsError(true);
			return;
		}

		setOrderInProgress(true);
		setAcceptTermsError(false);

		Orders.UpdateOrderPreferences(orderData.id, orderData.comments || "", $("#acepta-recibir").prop("checked")).then(() => {
			if (orderDetails.total > 0) {
				$("#tpv-form").trigger("submit");
			} else {
				Orders.CompleteOrder(orderData.id).then((response) => {
					if (response.status) {
						location.href = "/reserva-completada";
					} else {
						location.href = "/reserva-fallida";
					}
				});
			}
		});
	};

	const renderOrderDetails = () => {
		if (orderError) {
			return (
				<Link className="btn btn-brown" to="/">
					Volver
				</Link>
			);
		}
		if (!orderDetails) return null;

		const eventPrice = orderDetails.event.availability.price != -1 ? orderDetails.event.availability.price : orderDetails.event.price;
		const selectedDate = Helper.CreateDateCompatible(booking.selected_date);
		const detailsAddress = orderDetails.event.availability.address_alt || orderDetails.event.address || orderData.address;

		return (
			<div className="order-details">
				<div className="order-details-preorder">
					<div className="preorder-details-line title">
						<div className="preorder-name">
							<b>
								{orderDetails.event.nombre} | {orderDetails.event.negocio.name}
							</b>
							{detailsAddress !== null && detailsAddress !== "null" && <div className="details-address">{detailsAddress}</div>}
						</div>
						<div className="preorder-amount">{Helper.FormatAmount(((orderDetails.event.full_booking ? 1 : booking.pax) * eventPrice) / 100)}</div>
					</div>
					<div className="preorder-details-line secondary details">
						<MaterialIcon name="calendar_month" style={{ marginRight: "0.25em" }} />
						{Helper.FormatISODate(booking.selected_date)}
						{booking.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + booking.selection_length - 1)))}</span>} <MaterialIcon name="schedule" style={{ marginLeft: "0.5em", marginRight: "0.25em" }} />
						{orderDetails.event.availability.slot.split(" ")[1].substring(0, 5)}
						{Helper.RenderDurationEndTime(orderDetails.event.availability.slot.split(" ")[1], orderDetails.event.availability.duration || orderDetails.event.duration, " a ")}
						<MaterialIcon name="account_circle" style={{ marginLeft: "0.5em", marginRight: "0.25em" }} />
						{booking.pax}
					</div>

					{orderDetails.order.preorder_selection &&
						orderDetails.order.preorder_selection.map((item, idx) => {
							if (item.quantity <= 0) {
								return null;
							}

							return (
								<div className="preorder-details-line secondary" key={idx}>
									<div className="preorder-quantity">{item.quantity}</div>
									<div className="preorder-name">{item.name}</div>
									<div className="preorder-amount">{Helper.FormatAmount((item.quantity * item.price) / 100)}</div>
								</div>
							);
						})}
					<div className={"box-alergia mt"}>
						<label>
							<Content slug="etiqueta-campo-alergias" />
						</label>
						<textarea
							disabled={orderCompleted}
							id="notas-pedido"
							rows={5}
							placeholder={(orderData.comments && orderData.comments[0]) || placeholderCampoAlergias}
							defaultValue={orderData?.comments ? orderData.comments[0] : ""}
							onChange={(e) => {
								const n = { ...orderData };
								n.comments[0] = e.target.value;
								setOrderData(n);
							}}></textarea>
					</div>
					{orderDetails.order.extra_events &&
						orderDetails.order.extra_events.map((item, idx) => {
							const extraEventIdx = idx;
							if (item.quantity <= 0) {
								return null;
							}

							const isoDate = item.selectedSlot.split(" ")[0];
							const slot = item.selectedSlot.split(" ")[1].substring(0, 5);
							const selectedDate = Helper.CreateDateCompatible(isoDate);

							return (
								<React.Fragment key={"event-" + idx}>
									<div className="preorder-details-line title">
										<div className="preorder-name">
											<b>
												{item.nombre} | {item.negocio.name}
											</b>
											<div className="details-address">{item.address && item.address != "null" ? item.address : ""}</div>
										</div>
										<div className="preorder-amount">{Helper.FormatAmount(((item.full_booking ? 1 : item.quantity) * item.price) / 100)}</div>
									</div>
									<div className="preorder-details-line secondary details">
										<MaterialIcon name="calendar_month" />
										{Helper.FormatISODate(isoDate)}
										{item.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + item.selection_length - 1)))}</span>} <MaterialIcon name="schedule" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{slot}
										{Helper.RenderDurationEndTime(slot, item.duration, " a ")} <MaterialIcon name="account_circle" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{item.quantity}
									</div>

									{item.selectedExtras?.map((extra, idx) => {
										return (
											<div className="preorder-details-line secondary" key={"extra-" + idx}>
												<div className="preorder-quantity">{extra.quantity}</div>
												<div className="preorder-name">{extra.name}</div>
												<div className="preorder-amount">{Helper.FormatAmount((extra.quantity * extra.price) / 100)}</div>
											</div>
										);
									})}
									<div className={"box-alergia mt"}>
										<label>
											<Content slug="etiqueta-campo-alergias" />
										</label>
										<textarea
											disabled={orderCompleted}
											id="notas-pedido"
											rows={5}
											placeholder={(orderData?.comments && orderData?.comments.length > extraEventIdx + 1 && orderData?.comments[extraEventIdx + 1]) || placeholderCampoAlergias}
											defaultValue={orderData && orderData?.comments && orderData?.comments.length > extraEventIdx + 1 ? orderData.comments[extraEventIdx + 1] : ""}
											onChange={(e) => {
												const data = { ...orderData };
												data.comments[extraEventIdx + 1] = e.target.value;
												setOrderData(data);
											}}></textarea>
									</div>
								</React.Fragment>
							);
						})}
					{event?.offers_preorder == 1 && JSON.parse(event?.extras_menu) && (
						<div className="change-order-link">
							<Link to={"/venue/" + event.slug + "/extras"}>{L10n.__("¿Quieres modificar tu selección?")}</Link>
						</div>
					)}
				</div>
				<div className="order-details-total">
					<div>
						{orderDetails.label} ({L10n.__("IVA incluido")})
					</div>
					<div>
						{orderDetails.order.before_discounts != orderDetails.total && <span className="before-discounts">{Helper.FormatAmount(orderDetails.order.before_discounts / 100)}</span>}
						{Helper.FormatAmount(orderDetails.total / 100)}
					</div>
				</div>
			</div>
		);
	};

	if (!userName || !event || !booking || !orderDetails || !orderDetails.order || !sessionStorage.getItem("current_order_id")) return <LoadingIndicator />;

	return (
		<div className="pago">
			{loadingInProgress && <LoadingIndicator />}
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-sm-12 order-container">
						<div className="box">
							<div className={orderError ? "hidden" : ""}>
								<h3>
									{userName}, {L10n.__("completa tu reserva")}
								</h3>
								{Helper.IsResponsive() && (
									<div className="change-order-link">
										<Link to={subscriptionsContext.subscriptionsEnabled ? "/suscripcion/" + SubscriptionsService.GetCartData()["typeSlug"] + "/" + event.slug : "/venue/" + event.slug}>{L10n.__("¿Quieres modificarla?")}</Link>
									</div>
								)}
							</div>
							{renderOrderDetails()}
						</div>
						{!subscriptionsContext.subscriptionsEnabled && Helper.IsResponsive() && renderCajaDescuento()}

						{orderDetails.total > 0 && <TPVWidget order={orderData.id} amount={orderDetails.total} />}

						<div className={"disclaimer" + (orderError ? " hidden" : "")}>
							<label>
								<input type="checkbox" required className="accept-terms"></input> {L10n.__("Confirmo que he leído y acepto los")}{" "}
								<a rel="noreferrer" href="/terminos-y-condiciones" target="_blank">
									{L10n.__("términos de uso")}
								</a>{" "}
								{L10n.__("y la")}{" "}
								<a rel="noreferrer" href="/politica-de-privacidad" target="_blank">
									{L10n.__("política de privacidad")}
								</a>
								.
							</label>
						</div>
						{acceptTermsError && <div className="accept-terms-error">{L10n.__("Debes aceptar los términos de uso y la política de privacidad para continuar.")}</div>}

						<button
							disabled={orderInProgress}
							className={"btn btn-brown btn-pagar" + (orderError || orderCompleted ? " hidden" : "") + (orderInProgress ? " disabled" : "")}
							onClick={() => {
								if (!orderInProgress) completarReserva();
							}}>
							{orderInProgress ? <LoadingIndicator contained={true} dotColor="white" /> : orderDetails.total > 0 ? L10n.__("Pagar") : L10n.__("Confirmar")}
						</button>
						{orderDetails.total > 0 && <img alt="" src="/static/images/pago.png" className="formas-pago mobile-only" />}
						{orderDetails.total > 0 && <img alt="" src="/static/images/pago.png" className="formas-pago desktop-only" />}
					</div>
					{renderBookingSidebar()}
				</div>
			</div>
		</div>
	);
}
