import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { PreorderSelectionContext } from "../context/PreorderSelectionContext";
import { SubscriptionsContext } from "../context/SubscriptionsContext";

import Helper from "../services/Helper.jsx";
import Icon from "./Icon.jsx";
import Session from "../services/Session.jsx";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";
import SubscriptionsService from "../services/Subscriptions.jsx";

export default function PreorderTicket(props) {
	const isResponsive = window.innerWidth <= 992;
	const preorderSelectionContext = useContext(PreorderSelectionContext);
	const subscriptionsContext = useContext(SubscriptionsContext);

	function renderSidebarTotals() {
		const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price != -1 ? props.event.price : props.event.negocio.price;

		if (props.page == "upselling" && props.booking.preorder_selection && props.booking.preorder_selection.length > 0) {
			return (
				<>
					<div className="details-line" style={{ fontSize: "0.9em", marginTop: 20 }}>
						<span className="sidebar-quantity">{props.booking.pax}</span>
						{props.event.nombre} <span style={{ marginLeft: "auto" }}>{Helper.FormatAmount(((props.event.full_booking == 1 ? 1 : props.booking.pax) * price) / 100)}</span>
					</div>
					{props.booking.preorder_selection
						.filter((i) => i.quantity > 0)
						.map((item, idx) => {
							return (
								<div className="details-line" key={idx} style={{ fontSize: "0.9em" }}>
									<span className="sidebar-quantity">{item.quantity}</span>
									{item.name}
									<span style={{ marginLeft: "auto" }}>
										{item.quantity} &times; {Helper.FormatAmount(item.price / 100)}
									</span>
								</div>
							);
						})}
				</>
			);
		}

		return null;
	}

	function renderExtrasSelection() {
		if (preorderSelectionContext.preorderSelection.filter((i) => i.quantity > 0).length == 0) {
			return null;
		}

		return (
			<div className="preorder-selection-information preorder-ticket">
				{preorderSelectionContext.preorderSelection
					.filter((i) => i.quantity > 0)
					.map((item, idx) => {
						if (item.quantity == 0) return null;

						return (
							<div className="item" key={"preorder-item-" + idx}>
								<div className="title">
									{!isResponsive && <span className="qty">{item.quantity} </span>}
									{item.name}
									{isResponsive && <span className="qty"> &times;{item.quantity}</span>}
								</div>
								<div className="details">
									<div>
										{item.quantity} &times; {Helper.FormatAmount(item.price / 100)}
									</div>
								</div>
								<div
									className="delete-button"
									onClick={(e) => {
										const newPreorderSelection = [...preorderSelectionContext.preorderSelection];
										newPreorderSelection.splice(idx, 1);
										preorderSelectionContext.changeSelection(newPreorderSelection);
									}}>
									&times;
								</div>
							</div>
						);
					})}
			</div>
		);
	}

	function renderOtherSelection() {
		if (preorderSelectionContext.preorderSelection.length == 0) {
			return null;
		}

		return (
			<div className="preorder-selection-information">
				{preorderSelectionContext.preorderSelection.map((item, idx) => {
					return (
						<div className="item" key={"preorder-item-" + idx}>
							<div className="title">
								{item.nombre} | {item.negocio.name}
							</div>
							<div className="details">
								<div>
									<MaterialIcon name="calendar_month" /> {Helper.FormatISODate(item.selectedSlot.split(" ")[0])}
								</div>
								<div>
									<MaterialIcon name="schedule" /> {item.selectedSlot.split(" ")[1].substring(0, 5)}
								</div>
								<div>
									<MaterialIcon name="account_circle" /> {item.quantity}
								</div>
								<div>
									<MaterialIcon name="credit_card" /> {Helper.FormatAmount((item.price * (item.full_booking ? 1 : item.quantity) + item.selectedExtras?.reduce((acc, extra) => acc + extra.price * extra.quantity, 0)) / 100)}
								</div>
							</div>
							{item.selectedExtras.filter((e) => e.quantity > 0)?.length > 0 && (
								<div className="extras">
									<div className="extra-row">
										<span>{item.quantity}</span>
										<span>{item.nombre}</span>
										<span>{Helper.FormatAmount((item.price * (item.full_booking ? 1 : item.quantity)) / 100)}</span>
									</div>
									{item.selectedExtras.map((extra, extra_index) => {
										if (extra.quantity == 0) return null;
										return (
											<div className="extra-row" key={"extra-row-" + extra_index}>
												<span>{extra.quantity}</span> <span>{extra.name}</span>{" "}
												<span>
													{extra.quantity} &times; {Helper.FormatAmount(extra.price / 100)}
												</span>
											</div>
										);
									})}
								</div>
							)}
							<div
								className="delete-button"
								onClick={(e) => {
									const newPreorderSelection = [...preorderSelectionContext.preorderSelection];
									newPreorderSelection.splice(idx, 1);
									preorderSelectionContext.changeSelection(newPreorderSelection);
								}}>
								&times;
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	const price = props.event.availability.price != -1 ? props.event.availability.price : props.event.price != -1 ? props.event.price : props.event.negocio.price;

	// Ensure price is not NaN
	if (Number.isNaN(price)) {
		throw new Error("Price calculation resulted in NaN");
	}

	let preorderTotal = 0;
	if (props.booking.preorder_selection) {
		preorderTotal = props.booking.preorder_selection.reduce((p, v) => {
			const itemTotal = v.quantity * v.price;
			// Check each item total for NaN
			if (Number.isNaN(itemTotal)) {
				throw new Error("Preorder item total calculation resulted in NaN");
			}
			return p + itemTotal;
		}, 0);
	}

	if (Number.isNaN(preorderTotal)) {
		throw new Error("Preorder total calculation resulted in NaN");
	}

	const bookingMultiplier = props.event.full_booking ? 1 : props.booking.pax;
	// Check bookingMultiplier for NaN
	if (Number.isNaN(bookingMultiplier)) {
		throw new Error("Booking multiplier calculation resulted in NaN");
	}

	let totalPrice = preorderTotal + bookingMultiplier * price;
	// Final check for totalPrice
	if (Number.isNaN(totalPrice)) {
		throw new Error("Total price calculation resulted in NaN");
	}

	let maxPax = -1;
	if (subscriptionsContext?.subscriptionsEnabled) {
		maxPax = SubscriptionsService.GetEventoMaxPax(subscriptionsContext.userSubscriptions, props.event.id);

		// Ensure maxPax is not NaN
		if (Number.isNaN(maxPax)) {
			throw new Error("MaxPax calculation resulted in NaN");
		}

		// Ensure props.booking.pax is a number
		if (Number.isNaN(props.booking.pax)) {
			throw new Error("Booking pax is not a number");
		}

		// Ensure props.event.regular_price is not NaN
		if (Number.isNaN(props.event.regular_price)) {
			throw new Error("Event regular price calculation resulted in NaN");
		}

		if (props.booking.pax > maxPax) {
			const extraPaxCharge = (props.booking.pax - maxPax) * props.event.regular_price;
			// Check extraPaxCharge for NaN
			if (Number.isNaN(extraPaxCharge)) {
				throw new Error("Extra Pax Charge calculation resulted in NaN");
			}
			totalPrice += extraPaxCharge;
		}

		// Final check for totalPrice
		if (Number.isNaN(totalPrice)) {
			throw new Error("Total price calculation resulted in NaN after adding extra charges");
		}
	}

	return (
		<div className={"booking-information " + (props.page || "upselling")}>
			<div className="details-line availability-description">
				{props.event.nombre} | {props.event.negocio.name}
			</div>
			<div className="details-line">
				<MaterialIcon name="calendar_month" />
				{Helper.FormatISODate(props.booking.selected_date, Helper.DATE_VERBOSE_LONGEST).split(", ")[1]}
			</div>
			<div className="details-line">
				<MaterialIcon name="schedule" />
				{props.booking.selected_slot.substring(0, 5)}
				{Helper.RenderDurationEndTime(props.booking.selected_slot.substring(0, 5), props.event.availability.duration, " a ")}
			</div>
			<div className="details-line">
				<MaterialIcon name="account_circle" />
				{props.booking.pax} {props.booking.pax == 1 ? L10n.__("persona") : L10n.__("personas")}
			</div>
			{subscriptionsContext?.subscriptionsEnabled && maxPax > 0 && maxPax < props.booking.pax && (
				<div className="details-line">
					<span className="included-pax-count">{maxPax}</span> {maxPax == 1 ? L10n.__("Incluida en tu suscripción") : L10n.__("Incluidas en tu suscripción")}
				</div>
			)}
			<div className="details-line">
				<MaterialIcon name="credit_card" />
				{subscriptionsContext?.subscriptionsEnabled && totalPrice <= 0 ? L10n.__("Incluido en tu suscripción") : Helper.FormatAmount(totalPrice / 100)}
			</div>
			{props.promo_title && (
				<div>
					<Icon name="promo" />
					{props.promo_title}
				</div>
			)}
			{renderSidebarTotals()}
			<div className="change-order-link">
				<Link to={"/venue/" + props.event.negocio.slug}>{L10n.__("¿Quieres modificar tu reserva?")}</Link>
			</div>
			{props.page == "upselling" ? renderOtherSelection() : renderExtrasSelection()}
		</div>
	);
}
