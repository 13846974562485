/*
4242424242424242	Succeeds and immediately processes the payment.
4000000000003220	Requires 3D Secure 2 authentication for a successful payment.
4000000000009995	Always fails with a decline code of insufficient_funds.
*/

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import L10n from '../services/Locale';
import { useEffect, useRef, useState } from 'react';
import Helper from '../services/Helper';

const StripeCheckoutForm = props => {
    const [ payButtonEnabled, setPayButtonEnabled ] = useState(false);
    const [ termsAccepted, setTermsAccepted ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ show3dsWarning, setShow3dsWarning ] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setPayButtonEnabled(false);
        
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: "https://" + location.hostname + props.returnURL
            }
        });
        
        if (result.error.setup_intent.status == "succeeded") {
            
            location.href = "https://" + location.hostname + props.returnURL + "?setup_intent=" + result.error.setup_intent.id;
            return;
        }
        
        if (result.error) {
            setErrorMessage(result.error.message);
            setShowError(true);
            setPayButtonEnabled(true);
            return;
        }

        setShowError(false);
    };

    if (!elements) return null;

    return <form>
        <div className="stripe-element-container">
            <PaymentElement
                onReady={e => {
                    $(".pago-suscripcion").css({ opacity: 1 });
                }}
                onChange={e => {
                    setPayButtonEnabled(e.complete);
                }}
            />
        </div>

        <div className="disclaimer"><label><input onChange={e => {
            setTermsAccepted(e.target.checked);
            setShowError(false);
        }} type="checkbox" required="" className="accept-terms" /> {L10n.__("Confirmo que he leído y acepto los")} <a rel="noreferrer" href="/terminos-y-condiciones" target="_blank">{L10n.__("términos de uso")}</a> {L10n.__("y la")} <a rel="noreferrer" href="/politica-de-privacidad" target="_blank">{L10n.__("política de privacidad")}</a>.</label></div>

        {showError && <div className="terms-error">{errorMessage}</div>}

        <button onClick={e => {
            e.preventDefault();
            if (!termsAccepted) {
                setErrorMessage(L10n.__("Para continuar debes aceptar los términos de uso y la política de privacidad."));
                setShowError(true);
                return;
            }

            setShowError(false);
            setShow3dsWarning(true);
        }} disabled={!stripe || !payButtonEnabled} className={"btn btn-brown confirmar" + (!termsAccepted ? " disabled" : "")}>{L10n.__("Pagar")}</button>

        {show3dsWarning && <div className="i3ds-warning-backdrop">
            <div className="i3ds-warning">
                <div><p>{L10n.__("Es posible que tu banco te pida autorizar la tarjeta con un coste de 0,00 € como paso previo al cobro de tu suscripción.")}</p>
                <p>{L10n.__("Una vez completado este paso, se procederá al cargo de") + " " + Helper.FormatAmount(props.chargedAmount/100, false, true) + L10n.__("/mes por tu suscripción a") + " " + props.subscriptionName + "."}</p></div>
                <a href="#" className="btn btn-brown" onClick={e => {
                    e.preventDefault();
                    handleSubmit(e);
                    setShow3dsWarning(false);
                }}>{L10n.__("Continuar")}</a>
            </div>
        </div>}
    </form>;
};

export default StripeCheckoutForm;
