import React, { useState, useContext, useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import Session from "../services/Session";
import { HeaderContext } from "../services/HeaderContext";
import L10n from '../services/Locale';
import $ from "jquery";

import ContentHelper from "../services/ContentHelper";
import Icon from "./Icon";
import Settings from "../services/Settings";
import LanguageSelector from "./LanguageSelector";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import MaterialIcon from "./MaterialIcon";

export default function MainMenu(props) {
	const [logo, setLogo] = useState();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const headerContext = useContext(HeaderContext);
	const [customStaticPageURL, setCustomStaticPageURL] = useState(null);
	const [customStaticPageCaption, setCustomStaticPageCaption] = useState(null);
	const [ supportsLocalization, setSupportsLocalization ] = useState(null);
	const [ customStaticPageEnabled, setCustomStaticPageEnabled ] = useState(null);
	const isAdminPage = useMatch("/admin/*");
	const subscriptionsContext = useContext(SubscriptionsContext);

	function handleScroll(e) {
		if (window.scrollY > 1) {
			document.body.classList.add("scrolled");
		} else {
			document.body.classList.remove("scrolled");
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		handleScroll();

		Session.CheckIsLoggedIn().then((isLoggedIn) => {
			setIsLoggedIn(isLoggedIn);
		});

		$(function ($) {
			$(window).on("click", function (e) {
				const on_header = $(e.target).closest("header.desktop").length && !$(e.target).closest("header.desktop li:not(.user-account-button-container").length;
				if (!on_header) {
					$("header.desktop .navbar-container").removeClass("active");
				}
			});

			$(".user-account-button-close").on("click", function (e) {
				$("header.desktop .navbar-container").removeClass("active");
			});
		});

		ContentHelper.GetLogoImg("logo").then((element) => setLogo(element));

		Promise.all([ContentHelper.GetString("custom_static_page_url"), ContentHelper.GetString("custom_static_page_caption")]).then(([url, caption]) => {
			if (url && url.length) setCustomStaticPageURL(url);
			else setCustomStaticPageURL("/para-empresas");

			if (caption && caption.length) setCustomStaticPageCaption(caption);
			else setCustomStaticPageCaption(L10n.__("Para empresas"));
		});

		Session.GetBootstrapData().then(data => {
			setSupportsLocalization(data.supports_localization);
		});

		Settings.Get("SHOW_PAGINA_ESTATICA", 1).then(value => {
			setCustomStaticPageEnabled(parseInt(value) == 1);
		});

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const locationIsMisSuscripciones = location.pathname.match(/^\/mis-suscripciones/gi);
	
	return (
		<nav className="navbar navbar-expand-md">
			<div className="container full-width mw-95 py-1 py-lg-0">
				<div className="d-none d-lg-block">
					<Link className="navbar-brand" to="/" onClick={e => {
						$(".subscription-type").eq(0)?.trigger("click");
					}}>
						{logo}
					</Link>
				</div>
				<div className="navbar-collapse collapse" id="navbarResponsive">
					<ul className="navbar-nav mr-auto"></ul>
					<div className="navbar-container">
						<ul className="navbar-nav">
							{(headerContext.headerButtons || !isAdminPage) && (
								<li>
									{headerContext.headerButtons}
									{customStaticPageEnabled && customStaticPageURL != null && (
										<a target={customStaticPageURL.match(/^http/) ? "_blank" : "_self"} className="nav-link nav-btn" href={customStaticPageURL}>
											{customStaticPageCaption}
										</a>
									)}
									{!isAdminPage && subscriptionsContext?.subscriptionsEnabled && (
										<Link className="nav-link nav-btn btn suscripciones" to={locationIsMisSuscripciones ? "/" : "/mis-suscripciones"}>
											{L10n.__(locationIsMisSuscripciones ? <MaterialIcon name="arrow_left_alt" /> : "Mis suscripciones")}
										</Link>
									)}
								</li>
							)}
							{supportsLocalization && <LanguageSelector />}
							<li className="user-account-button-container">
								<Icon
									name={isLoggedIn ? "user-selected" : "user"}
									size="2"
									className="user-account-button"
									onMouseEnter={() => {}}
									onMouseLeave={() => {}}
									onClick={() => {
										$("header.desktop .navbar-container").toggleClass("active");
									}}
								/>
							</li>
						</ul>
						<ul className="navbar-nav user-actions">
							{Session.RenderUserActions(null, true)}
							<li className="user-account-button-close">
								<div className="tock-icon"></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}
